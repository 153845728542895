<template lang="">
    <div class="tabs" :variants="variants">
        <div class="tabs__block" ref="tabBlock">
        </div>
        <div class="tabs__item" v-for="variant in variants" :key="variant.name" :class="{'tabs__item--active' : activeTab === variant.name}" @click="setActiveTab(variant.name)">
            {{variant.title}}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        variants: {
            type:Array,
        },
        activeTab: {
            type: String,
            default:'chat'
        }
    },

    methods: {
        setActiveTab(currentTab) {
            let block = this.$refs.tabBlock;
            console.log(block);
            if(currentTab === 'chat' && block.classList.contains('tabs__block--personal')) {
            block.classList.remove('tabs__block--personal')
            } else if(currentTab === 'personality') {
            block.classList.add('tabs__block--personal')
            }
            this.$emit('setActiveTab', currentTab);
        }
    }
}
</script>
<style scoped lang="scss">
    .tabs {
        position: relative;
        width: 100%;
        background: var(--bg-color);
        display: flex;
        justify-content: space-between;
        padding: 3px;
        border-radius: 12px;
        &__item {
            padding: 6px 0px;
            width: 50%;
            text-align: center;
            @include text;
            z-index: 2;
            color:#A1A1A1;
            &--active {
                color:white;
            }
        }
        &__block{
            position: absolute;
            left: 3px;
            top: 3px;
            background: var(--button-bg);
            width: 50%;
            height: 30px;
            z-index: 1;
            border-radius: 10px;
            transition: 0.3s ease all;
            &--personal {
                transition: 0.3s ease all;
                left: 100%;
                transform: translateX(-100%);
                
            }
        }
    }
</style>