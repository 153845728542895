<template lang="">
    <div class="setting__item">
        <div class="setting__item-info">
            <slot></slot>
            <h4 class="setting__item-title">{{title}}</h4>
        </div>
        <div class="setting__item-arrow" v-if="!choose">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M14.8535 11.6465L10.3535 7.1465C10.1597 6.9593 9.85254 6.9593 9.65875 7.1465C9.46014 7.33833 9.45465 7.65486 9.64648 7.85347L13.793 12L9.64648 16.1464C9.55273 16.2402 9.50006 16.3673 9.50006 16.4999C9.5 16.776 9.72388 16.9999 10 17C10.1326 17.0001 10.2598 16.9474 10.3535 16.8535L14.8535 12.3535C14.8534 12.3535 14.8535 12.3534 14.8535 12.3535C15.0486 12.1582 15.0487 11.8416 14.8535 11.6465Z" fill="#A1A1A1"/>
        </svg>
        </div>
        <div v-else-if="choose === true && active === true">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18.3535 7.57359C18.1602 7.37638 17.8437 7.37321 17.6465 7.56651L9.83983 15.3731L6.3535 11.8868C6.15911 11.6963 5.84807 11.6963 5.65367 11.8868C5.45647 12.0801 5.4533 12.3966 5.64653 12.5938L9.48638 16.4336C9.58 16.5276 9.70726 16.5803 9.83989 16.5801C9.97252 16.5802 10.0997 16.5275 10.1933 16.4336L18.3535 8.27348C18.5441 8.07908 18.5441 7.76798 18.3535 7.57359Z" fill="#A1A1A1"/>
            </svg>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            iconLink: this.icon
        }
    },
    props: {
        icon: String,
        title: String,
        choose: {
            type:Boolean,
            default: false,
        },
        active: {
            type:Boolean,
        }
    },
}
</script>
<style scoped lang="scss">
    .setting__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 16px;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        &:last-of-type {
        border-bottom: 0;
        }
        &-info{
            display: flex;
            align-items: center
        }
        &-title {
            @include text;
            color:var(--text-color);
        }
    }
</style>