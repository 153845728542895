<template lang="">
    <div class="setting__header">
        <div class="setting__header-back" @click="$router.go(-1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15.1035 17.6465L9.45696 12L15.1034 6.35355C15.2906 6.15977 15.2906 5.85264 15.1034 5.65885C14.9116 5.46024 14.5951 5.45475 14.3965 5.64658L8.39648 11.6466C8.39642 11.6466 8.39648 11.6465 8.39648 11.6466C8.20129 11.8418 8.20123 12.1584 8.39648 12.3536L14.3965 18.3536C14.4901 18.4475 14.6174 18.5002 14.75 18.5C14.8826 18.5 15.0097 18.4474 15.1034 18.3536C15.2987 18.1584 15.2987 17.8418 15.1035 17.6465Z" fill="#F9F9F9"/>
            </svg>
        </div>
        <h1 class="setting__header__title">
            {{title}}
        </h1>
        <button
        v-if="hasInfo"
          class="modal__info"
          :class="{
            'modal__info--hidden': hasInfo === false,
          }"
          @click="showInfo"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5955 8.10529C11.2598 7.7594 9.89667 8.56177 9.55078 9.89746C9.48175 10.165 9.64264 10.4378 9.91016 10.5068C10.1777 10.5759 10.4505 10.415 10.5195 10.1475C10.6462 9.65424 11.0146 9.25903 11.4977 9.09796C12.2835 8.836 13.1328 9.26068 13.3947 10.0464C13.6567 10.8322 13.2321 11.6815 12.4463 11.9434C11.8427 12.1677 11.4483 12.7508 11.4648 13.3945V14.023C11.465 14.299 11.6888 14.5226 11.9648 14.5225C12.2408 14.5223 12.465 14.2985 12.4648 14.0225V13.3945C12.4513 13.1797 12.5723 12.9789 12.7686 12.8906C13.5674 12.6208 14.1763 11.9662 14.3877 11.15C14.7336 9.81433 13.9312 8.45117 12.5955 8.10529ZM11.9647 15.3974C11.6194 15.3974 11.3397 15.6772 11.3397 16.0224C11.3397 16.3676 11.6194 16.6474 11.9647 16.6474C12.3098 16.6474 12.5897 16.3676 12.5897 16.0224C12.5897 15.6772 12.3098 15.3974 11.9647 15.3974ZM12 2C6.47717 2 2 6.47717 2 12C2 17.5228 6.47717 22 12 22C17.5201 21.9935 21.9935 17.5201 22 12C22 6.47717 17.5228 2 12 2ZM12 21C7.02942 21 3 16.9706 3 12C3 7.02942 7.02942 3 12 3C16.9683 3.00543 20.9946 7.03168 21 12C21 16.9706 16.9706 21 12 21Z"
              fill="#F9F9F9"
            />
          </svg>
        </button>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
        },
        hasInfo: {
                type: Boolean,
                default:false,
            }
    },
    methods: {
        showInfo() {
            this.$emit('showInfo')
        }
    }
}
</script>
<style scoped lang="scss">
    .setting__header {
        display: flex;
        justify-content: center;
        margin-top: 6px;
        position: relative;
    }
    .setting__header__title {
        @include h2;
        max-width: 225px;
        text-align: center;
    }
    .setting__header-back {
        position: absolute;
        left: 0;
        top: 2px;
    }
    .modal__info {
        background: transparent;
        position: absolute;
        right: 0;
        top: 2px;
    }
</style>