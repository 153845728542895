<template>
    <div class="user-info__avatar">
        <img :src="$store.getters.getUserAvatar" alt="">
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped lang="scss">
    .user-info__avatar {
        width: 132px;
        height: 132px;
        border-radius: 50%;
        background: var(--secondary-bg-color);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -61px;

        img {
            width: 122px;
            height: 122px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
</style>