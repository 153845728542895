<template lang="">
    <div class="login">
        <telegram-login-temp
    mode="callback"
    telegram-login="eva_tgbot"
    @callback="yourCallbackFunction"
  />
    </div>
</template>
<script>
import { telegramLoginTemp } from 'vue3-telegram-login'
export default {
    components: { telegramLoginTemp},
    methods: {
        yourCallbackFunction(user) {
  // gets user as an input
  // id, first_name, last_name, username,
  // photo_url, auth_date and hash
  this.$store.state.userData.userID = user.id
  this.$store.state.userData.name = user.username
  this.$router.push('/')
  this.$store.dispatch('getUserData');
}
  }
}
</script>
<style lang="scss">
 

    .login {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
</style>