<template lang="">
    <div>
        <ratesHeader :rate="$store.getters.getCurrentRate"></ratesHeader>
        <div class="rates-table__wrapper">
            <ratesTable @payment="setPay"></ratesTable>
        </div>
    </div>
</template>
<script>
import ratesHeader from '@/components/ratesHeader.vue';
import ratesTable from '@/components/ratesTable.vue';
import router from '@/router/router';
export default {
    data() {
        return {
            payment:true,
        }
    },
    components: {
        ratesHeader, ratesTable
    },
    methods: {
        setPay(type) {
            router.push('payment');
            this.$store.commit('setPayRate', type)
    }
}
}
</script>
<style scoped lang="scss">
    .rates-table__wrapper {
        overflow: hidden;
    }
</style>