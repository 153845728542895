<template lang="">
<input type="radio" class="list__item-radio" :name="itemsGroup" :id="item.name"  :value=" type === 'chat' ? item : item.name" @change="changeItem(select)" v-model="select"/>
<label :for="item.name">
   <div class="list__item" :class="{
    'list__item--active' : (checked === true && this.$store.state.userData.currentPerson === item) || (checked === true && this.$store.state.userData.currentChat.id === item.id) || this.$store.state.userData.currentPerson === item.name || this.$store.state.userData.currentChat === item.name,
   }">
      <h3 class="list__item-title">{{ item.name }}</h3>
      <div v-if="type === 'chat' && item.deletable != false" class="list-item__func">
        <button
        class="list__item-btn list__item-edit edit-chat"
        @click="editChat(item)"
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="35"
            height="35"
            rx="7.5"
            stroke="#A1A1A1"
            stroke-opacity="0.4"
          />
          <path
            d="M13 18.2578V22.5C13 22.4998 13 22.5002 13 22.5C13.0002 22.776 13.224 23.0002 13.5 23H17.7422C17.8748 23 18.002 22.9473 18.0957 22.8535L25.0219 15.9258C25.0232 15.9246 25.025 15.9242 25.0262 15.923C25.0275 15.9217 25.0278 15.92 25.0291 15.9187L27.8535 13.0937C28.0486 12.8984 28.0487 12.5818 27.8535 12.3867L23.6133 8.14648C23.6132 8.14642 23.6133 8.14655 23.6133 8.14648C23.418 7.95136 23.1014 7.95123 22.9062 8.14648L13.1465 17.9043C13.0527 17.998 13 18.1252 13 18.2578ZM23.2598 9.20697L26.793 12.7402L24.6721 14.8616L21.1384 11.3279L23.2598 9.20697ZM14 18.4648L20.4313 12.0349L23.9651 15.5687L17.5352 22H14V18.4648ZM27.5 18C27.2239 18 27 18.2239 27 18.5V25C26.9986 26.104 26.104 26.9986 25 27H11C9.896 26.9986 9.0014 26.104 9 25V11C9.0014 9.896 9.896 9.0014 11 9H17.5C17.7761 9 18 8.77612 18 8.5C18 8.22388 17.7761 8 17.5 8H11C9.34387 8.00183 8.00183 9.34387 8 11V25C8.00183 26.6561 9.34387 27.9982 11 28H25C26.6561 27.9982 27.9982 26.6561 28 25V18.5C28 18.2239 27.7761 18 27.5 18Z"
            fill="#A1A1A1"
          />
        </svg>
      </button>
        <button
        class="list__item-btn list__item-delete"
        @click="deleteItem(item)"
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="35"
            height="35"
            rx="7.5"
            stroke="#A1A1A1"
            stroke-opacity="0.4"
          />
          <path
            d="M26.5 12H24.5H22V10.5C21.9987 9.11981 20.8802 8.00128 19.5 8H16.5C15.1199 8.00146 14.0015 9.11987 14 10.5V12H11.5C11.4998 12 11.5002 12 11.5 12H9.5C9.22388 12 9 12.2239 9 12.5C9 12.7761 9.22388 13 9.5 13H11V25.5C11.0015 26.8801 12.1199 27.9985 13.5 28H22.5C23.8802 27.9987 24.9987 26.8802 25 25.5V13H26.5C26.7761 13 27 12.7761 27 12.5C27 12.2239 26.7761 12 26.5 12ZM15 10.5C15.0009 9.67194 15.6719 9.00092 16.5 9H19.5C20.3281 9.00092 20.9991 9.67194 21 10.5V12H15V10.5ZM24 25.5C23.9991 26.3281 23.3281 26.9991 22.5 27H13.5C12.6719 26.9991 12.0009 26.3281 12 25.5V13H14.5H21.5H24V25.5Z"
            fill="#A1A1A1"
          />
        </svg>
      </button>
      </div>
      <button
        v-else-if="item.editable != false"
        class="list__item-btn list__item-edit"
        @click="editPerson(item)"
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="35"
            height="35"
            rx="7.5"
            stroke="#A1A1A1"
            stroke-opacity="0.4"
          />
          <path
            d="M13 18.2578V22.5C13 22.4998 13 22.5002 13 22.5C13.0002 22.776 13.224 23.0002 13.5 23H17.7422C17.8748 23 18.002 22.9473 18.0957 22.8535L25.0219 15.9258C25.0232 15.9246 25.025 15.9242 25.0262 15.923C25.0275 15.9217 25.0278 15.92 25.0291 15.9187L27.8535 13.0937C28.0486 12.8984 28.0487 12.5818 27.8535 12.3867L23.6133 8.14648C23.6132 8.14642 23.6133 8.14655 23.6133 8.14648C23.418 7.95136 23.1014 7.95123 22.9062 8.14648L13.1465 17.9043C13.0527 17.998 13 18.1252 13 18.2578ZM23.2598 9.20697L26.793 12.7402L24.6721 14.8616L21.1384 11.3279L23.2598 9.20697ZM14 18.4648L20.4313 12.0349L23.9651 15.5687L17.5352 22H14V18.4648ZM27.5 18C27.2239 18 27 18.2239 27 18.5V25C26.9986 26.104 26.104 26.9986 25 27H11C9.896 26.9986 9.0014 26.104 9 25V11C9.0014 9.896 9.896 9.0014 11 9H17.5C17.7761 9 18 8.77612 18 8.5C18 8.22388 17.7761 8 17.5 8H11C9.34387 8.00183 8.00183 9.34387 8 11V25C8.00183 26.6561 9.34387 27.9982 11 28H25C26.6561 27.9982 27.9982 26.6561 28 25V18.5C28 18.2239 27.7761 18 27.5 18Z"
            fill="#A1A1A1"
          />
        </svg>
      </button>
    </div>
  </label>
</template>
<script>
import { useI18n } from 'vue-i18n'
export default {
  data() {
    return {
      isActive: false,
      select: '',
    }
  },
    props: {
        item: {
            type: Object,
        },
        type: String,
        checked: Boolean
    },
    computed: {
      itemsGroup() {
        if(this.type === 'chat') {
          return 'chat'
        } else {
          return 'personal'
        }
      }
    },
    methods: {
        deleteItem(item) {
            this.$store.commit('openModal', {title: this.$t('youShure'), type:'chat', hasInfo: false});
            this.$store.commit('setCurrentItemList', {'name': item.name, 'id': item.id,  'type' : 'chat'});
            console.log(item)
        },
        editPerson(item) {
          this.$store.commit('setCurrentItemList', item)
          this.$store.commit('openModal', {title: this.$t('personSettings'), type:'person', hasInfo: true})
        },
        changeItem(item) {
          this.$emit('change', item);
        },
        editChat(item) {
          this.$store.commit('openModal', {title: this.$t('editChat'), type:'editChat', hasInfo: true})
          this.$store.commit('setCurrentItemList', {'name': item.id, 'id': item.id, 'type' : 'chat'});
        }
    },
    
   setup() {
        const { t, locale } = useI18n({useScope:'global'})
        return {
        t,locale
  }
    }
}
</script>
<style scoped lang="scss">
      .list__item {
    padding: 5px 12px;
    background: var(--bg-color);
    margin-bottom: 8px;
    border-radius: 12px;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--active {
      border:1px solid var(--button-bg);
    }
    &-title {
      @include text;
    }
    &-btn {
      background: none;
      outline: none;
      border: none;
    }
    &-radio {
      display: none;
    }
  }
.list-item__func {
  display: flex;
}
  .edit-chat {
    margin-right: 8px;
  }
</style>