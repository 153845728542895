<template lang="">
    <setting-header :title="$t('Payment')"></setting-header>
    <ratesHeader :rate="$store.getters.getPayRate"></ratesHeader>
    <paymentBlock></paymentBlock>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingHeader from '@/components/UI/settingHeader.vue';
import paymentBlock from '@/components/paymentBlock.vue';
import ratesHeader from '@/components/ratesHeader.vue';
export default {
    components: {settingHeader, paymentBlock, ratesHeader},
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>

<style lang="">
    
</style>