<template lang="">
    <div class="settings">
        <h1 class="settings__title">{{$t('settings')}}</h1>
        <settings-list></settings-list>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
import settingsList from '@/components/settingsList.vue';
export default {
    components: {settingsList},
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .settings__title {
        @include h2;
        text-align: center;
    }
</style>