<template lang="">
    <fieldset>
        <label for="{{name}}">{{label}}</label>
        <input :value="modelValue" @input="updateInput" :class="{
            'error' : error === true
        }" type="text" name="{{name}}" id="" :placeholder="placeholder">
        <span class="error-text">{{errorText}}</span>
    </fieldset>
</template>
<script>
export default {
    data() {
        return {
            error:false,
            errorText: '',
        }
    },
    props:{
        label: {
            type: String,
            default: '',
        },
        modelValue: [String, Number],
        name: String,
        placeholder: String,
    },
    methods: {
        updateInput(event) {
             this.$emit('update:modelValue', event.target.value)
        }
    },
    watch: {
        modelValue() {
            if(this.modelValue === '') {
                this.error = true;
                this.errorText = 'Введите значение';
                this.$emit('error', true);
            } else {
                this.error = false;
                this.errorText = '';
                this.$emit('error', false);
            }
        }
    }
}
</script>
<style scoped lang="scss">
    fieldset {
        border:none;
    }
     input {
        @include input;
        width: 100%;
        margin-top: 8px;
        &.error {
            border-color: #E73333
        }
    }
    label {
        @include label;
    }
    .error-text {
        @include footnote;
        color:#E73333;
        margin-top: 4px;
    }
</style>