<template lang="">
  <div class="rates__slider">
    <swiper class="rates__tabs" :slides-per-view="3" :modules="[Thumbs]"  :space-between="15"  watch-slides-progress
    @swiper="setThumbsSwiper">
      <swiper-slide>
      <div class="rates__tab">
        {{ $t("premiumPlan") }}
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="rates__tab">
        {{ $t("mediumPlan") }}
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="rates__tab">
        {{ $t("freePlan") }}
      </div>
    </swiper-slide>
    </swiper>
    <swiper
    :slidesPerView="'auto'"
    :centeredSlides="true"
    :spaceBetween = "20"
    class="rates__items"
    :modules="[Thumbs]" :thumbs="{ swiper: thumbsSwiper }"
  >
    <swiper-slide>
      <div class="rates__item">
        <img src="../assets/ratebgprem.png" alt="" class="rates__item-bg">
        <div class="rates__item-info">
          <h3 class="rates__item-title">{{ $t("premiumPlan") }}</h3>
        <ul class="rate__features">
          <li>
            700 000 <span>{{ $t("GPT3req") }}</span>
          </li>
          <li>
            70 000 <span>{{ $t("GPT4req") }}</span>
          </li>
          <li>100 <span>{{ $t("ratesStable") }}</span></li>
          <li>
            <span>200 {{ $t("voiceTokensTitle") }}</span>
          </li>
          <li>
            <span>150 {{ $t("voiceOutputTitle") }}</span>
          </li>
          <li>
            <span>{{ $t("ratesPromts") }}</span>
          </li>
          <li>
            <span>{{ $t("chatsHistory") }}</span>
          </li>
          <li>
            <span>{{ $t("ratesRef") }}</span>
          </li>
          <li>
            {{ $t("settingFam") }}
          </li>
        </ul>
        </div>
        
        <div class="rates__item-bottom">
          <div v-if="$store.state.userData.subInfo.name != 'premium'" class="rate__item-btn">
            <span
            class="rate-btn btn--gradient"
            @click="this.$emit('payment', 'premium')"
          >
          {{ $t("Select") }}
            </span>
          </div>
          <div v-else class="rate__item-btn">
            <span
            class="rate-btn btn--gradient disable"
          >
          {{ $t("buying") }}
            </span>
          </div>
          <div v-if="locale === 'ru'" class="rate__item-price">
            {{ $store.state.AppSettings.shopPrices[2].price.ru }} ₽
          </div>
          <div v-else  class="rate__item-price">
            {{ $store.state.AppSettings.shopPrices[2].price.en }} $
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="rates__item">
        <img src="../assets/ratebg.png" alt="" class="rates__item-bg">
        <div class="rates__item-info">
        <h3 class="rates__item-title">{{ $t("mediumPlan") }}</h3>
        <ul class="rate__features">
          <li>
            400 000 <span>{{ $t("GPT3req") }}</span>
          </li>
          <li>100 <span>{{ $t("ratesStable") }}</span></li>
          <li>
            <span>100 {{ $t("voiceTokensTitle") }}</span>
          </li>
          <li>
            <span>{{ $t("ratesPromts") }}</span>
          </li>
          <li>
            <span>{{ $t("chatsHistory") }}</span>
          </li>
          <li>
            <span>{{ $t("ratesRef") }}</span>
          </li>
        </ul>
      </div>
        <div class="rates__item-bottom">
          <div v-if="$store.state.userData.subInfo.name != 'medium'" class="rate__item-btn">
            <span
            class="rate-btn btn--gradient"
            @click="this.$emit('payment', 'medium')"
          >
          {{ $t("Select") }}
            </span>
          </div>
          <div v-else class="rate__item-btn">
            <span
            class="rate-btn btn--gradient disable"
          >
          {{ $t("buying") }}
            </span>
          </div>
          <div v-if="locale === 'ru'" class="rate__item-price">
            {{ $store.state.AppSettings.shopPrices[1].price.ru }} ₽
          </div>
          <div v-else  class="rate__item-price">
            {{ $store.state.AppSettings.shopPrices[1].price.en }} $
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="rates__item">
        <div class="rates__item-info">
        <h3 class="rates__item-title">{{ $t("freePlan") }}</h3>
        <ul class="rate__features">
          <li>
            200 000 <span>{{ $t("GPT3req") }}</span>
          </li>
          <li>100 <span>{{ $t("ratesStable08") }}</span></li>
          <li>
            <span>{{ $t("ratesPromts") }}</span>
          </li>
          <li>
            <span>{{ $t("chatsHistory") }}</span>
          </li>
        </ul>
      </div>
        <div class="rates__item-bottom">
          <div v-if="$store.state.userData.subInfo.name != 'start'" class="rate__item-btn">
            <span
            class="rate-btn btn--gradient"
            @click="this.$emit('payment', 'start')"
          >
          {{ $t("Select") }}
            </span>
          </div>
          <div v-else class="rate__item-btn">
            <span
            class="rate-btn btn--gradient disable"
          >
          {{ $t("buying") }}
            </span>
          </div>
          <div v-if="locale === 'ru'" class="rate__item-price">
            {{ $store.state.AppSettings.shopPrices[0].price.ru }} ₽
          </div>
          <div v-else  class="rate__item-price">
            {{ $store.state.AppSettings.shopPrices[0].price.en }} $
          </div>
        </div>
      </div>
    </swiper-slide>
    </swiper>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCoverflow } from 'swiper/modules';
import { ref } from 'vue';
  import { Thumbs } from 'swiper/modules';
// Import Swiper styles
  import 'swiper/css';
import { useI18n } from "vue-i18n";
export default {
   components: {
      Swiper,
      SwiperSlide,
    },
  setup() {
    const thumbsSwiper = ref(null);
      const setThumbsSwiper = (swiper) => {
        thumbsSwiper.value = swiper;
      };
    const { t, locale } = useI18n({ useScope: "global" });
    return { 
      t, 
      locale,
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
      EffectCoverflow
    };
  },
};
</script>
<style scoped lang="scss">

.rates__slider {
  margin-top: 24px;
}
.rates__tabs {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 16px;
  .swiper-slide-thumb-active .rates__tab{
    color: var(--link-color);
    font-weight: 600;
    font-size: 22px;
  }
  
}

.rates__tab {
  @include footnote;
  text-align: center;

  &--active {
    color: var(--link-color);
    font-weight: 600;
    font-size: 22px;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.rates__item {
  padding: 24px 12px;
  background: #2e2e32;
  border-radius: 24px;
  position: relative;
  overflow: hidden
}
.rates__item-title {
  @include h1;
  text-align: center;
}

.rate__features {
  margin-top: 20px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 30px;
  li {
    margin-bottom: 8px;
  }
}

.rates__item-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:24px;
}

.rate__item-price {
  @include h1;
}

.rate__item-btn span {
  padding: 14px 30px;
  border-radius: 12px;
}

.rates__item-bg {
position: absolute;
left: 0;
top: 0;
z-index: 1;
}
.swiper-wrapper {
  justify-content: stretch;
}
.rates__items .swiper-slide {
  width: 80%;
  height: auto;
}

.rates__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}
</style>
