<template lang="">
    <form class="person__info" @submit.prevent='addNewPerson'>
        <div class="person__info-item">
            <formInput v-model="personName" :label="$t('personName')" :name="'person-name'" :placeholder="$t('personNamePlaceholder')"></formInput>
        </div>
        <div class="person__info-item">
            <formInput v-model="personprompt" :label="$t('personPromts')" :name="'person-prompt'" :placeholder="$t('personPromtsPlaceholder')" ></formInput>
        </div>
        <button type="submit" class="btn btn--second" :class="{
            'disabled' : personName === '' || personprompt === ''
        }">{{$t('saveButton')}}</button>
    </form>
</template>
<script>
import { useI18n } from 'vue-i18n'
import formInput from './UI/formInput.vue';
export default {
    data() {
        return {
            personID: '',
            personName: '',
            personprompt: '',
          
        }
    },
    components: {formInput},
    props:{
        person: {
           type: Object,
        }
    },
    mounted() {
        if(this.person != null) {
            this.personName = this.person.name;
            this.personprompt = this.person.prompt;
            this.personID = this.person.id
        }
    },
    methods: {
        addNewPerson() {
            console.log(this.personName);
            if(this.person === false || this.person === null) {
                this.$store.commit('addPersonItem', {'id': Math.random().toString(16).slice(2) , name: this.personName , prompt: this.personprompt, editable : true})
                console.log(this.person)
            } else {
                this.$store.commit('changePerson', {'id': this.personID,'name' : this.personName, 'prompt': this.personprompt})
            }
        },

    },
    setup() {
    const { t} = useI18n({useScope:'global'})
    return {t}
  }

}
</script>
<style scoped lang="scss">
    .person__info-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    button {
        margin-top: 24px;
    }
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
</style>