import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store';
//i18n
import { createI18n, useI18n } from 'vue-i18n'
import { defaultLocale, languages} from './i18n/index';

const messages = Object.assign(languages)

const i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    messages: messages,

})

createApp(App, {
    setup() {
        const {t} = useI18n()
        return {t}
    }
})
.use(router)
.use(store)
.use(i18n)
.mount('#app')
