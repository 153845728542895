import { createStore } from "vuex";
import axios from "axios";
import router from "@/router/router";
import { useTelegram } from "@/hooks/useTelegram";
const {tg, userId} = useTelegram();
// const userId = 1483218909  //1483218909

export default createStore( {
    state: {
        isLoading: true,
        API_URL:'https://pure-collie-vastly.ngrok-free.app', //https://pure-collie-vastly.ngrok-free.app
        isAuth: false,
        userData: {
            userID: userId ? userId : null,
            avatar: '',
            name: '',
            subDate: '',
            textTokens: '',
            imageTokens: '',
            gpt4Tokens: '',
            voiceRequests: '', 
            voiceOverRequests: '',
            currentPerson: '',
            currentChat: null,
            subInfo: {},
            newFamilyUser: '',
            familyList:[],
            chatList: [
            ],
            personsList: [
            ],
            settings: {
                AIVersion: {},
                DiffVersion: {},
                voiceReq: false,
                voiceAnswer: false,
                imageGen: false,
                imageRes: {},
                imageStyle : {},
                imageCount:'',
                refSettings: {
                    available: false,
                    timeToSend: null,
                    exchangeRate: true,
                    exchangeBitcoin: false,
                    weather: true,
                },
                familySharing:true,
                language: {},
                currentRate: {name:"free", label:'Бесплатный', price: 0},
                imageStatDays:[],
                tockensStatDays:[],
                tockensStatValues:[],
                imageStatValues:[],
            }
        },

        modalIsOpen: false,
        modalOptions: [],
        searchQuery: '',
        activeTab : 'chat',
        currentItemList:'',
        rates: [
            {name:"premium", label:'Премиум', price: 1990},
            {name:"better", label:'Улучшенный', price: 890},
            {name:"base",  label:'Базовый', price: 299},
        ],
        payRate:null,
        AppSettings : {
            AIVersions: [],
            DiffVersions: [],
            imageStyles: [],
            imageRes: [],
            languages: [
                {name:'Русский', value: 'ru'},
                {name:'English', value: 'en'},
            ],
            imageCounts: [1,2,3,4],
            shopPrices: []
        }
    },
    getters: {
        getUserID(state) {
            return state.userData.userID
        },
        getUserAvatar(state) {
            return state.userData.avatar;
        },
        getChatsList(state) {
        return state.userData.chatList.filter(it => it.name.toLowerCase().includes(state.searchQuery));
          
        },
        getPersonsList(state) {
        return state.userData.personsList.filter(it => it.name.toLowerCase().includes(state.searchQuery));
        },
        getVoiceReq(state) {
            return state.userData.settings.voiceReq;
        },
        getFamilyList(state) {
            return state.userData.familyList
        },
        getVoiceAnswer(state) {
            return state.userData.settings.voiceAnswer;
        },
        getImageGen(state) {
            return state.userData.settings.imageGen;
        },
        getTimetoSend(state) {
            return state.userData.settings.refSettings.timeToSend
        },
        getImageStyle(state) {
            return state.userData.settings.imageStyle;
        },
        getImageCount(state) {
            return state.userData.settings.imageCount;
        },
        getImageRes(state) {
            return state.userData.settings.imageRes.value;
        },
        getRefSettings(state) {
            return state.userData.settings.refSettings
        },
        getFamilySharing(state) {
            return state.userData.settings.familySharing
        },
        getLanguage(state) {
            return state.userData.settings.language.value
        },
        getPayRate(state) {
            return state.payRate
        },
        getCurrentRate(state) {
            return state.userData.subInfo
        }
    },
    mutations: {
        //list mutations
        setLoading(state) {
            state.isLoading = false
        },

        deleteListItem(state) {
            state.userData.chatList = state.userData.chatList.filter(it => it.name !== state.userData.currentChat.name);
            state.modalIsOpen = false;
        },

        deleteFamilyItem(state, user) {
            console.log(user);
            state.userData.familyList = state.userData.familyList.filter(it => it.name !== user.name);
        },

        addPersonItem(state, item) {
            state.userData.personsList.push(item);
            state.modalIsOpen = false;
            state.userData.currentPerson = null;
        },
        resetPerson(state) {
            state.userData.currentPerson = null;
        },
        deleteAllChats(state) {
            state.userData.chatList = [];
            state.userData.currentChat = null;
        },
        openModal(state, options) {
            state.modalIsOpen = true;
            state.modalOptions = options;
        },
        setFamilyUser(state, id) {
            state.userData.newFamilyUser = id;
            state.modalIsOpen = false;
        },
        closeModal(state) {
            state.modalIsOpen = false;
            state.userData.currentPerson = null;
        },
        setCurrentItemList(state, item) {
            if(item.type === 'chat') {
                state.userData.currentChat = state.userData.chatList.find(it => it.id === item.id)
            } else {
                state.userData.currentPerson = state.userData.personsList.find(it => it.name === item.name)
            }
        },
        changePerson(state, item) {
            let currentPerson = state.userData.personsList.findIndex(it => it.id === item.id );
            state.userData.personsList[currentPerson].name = item.name;
            state.userData.personsList[currentPerson].prompt = item.prompt;
            state.modalIsOpen = false;
            state.userData.currentPerson = null;
        },
        changeChat(state, item) {
            let currentChat = state.userData.chatList.findIndex(it => it.id === state.userData.currentChat.id );
            state.userData.chatList[currentChat].name = item;
            state.modalIsOpen = false;
        },
        setSearchQuery(state, searchQuery) {
            state.searchQuery = searchQuery.toLowerCase();
        },
        resetSearch(state) {
            state.searchQuery ='';
        },
        setActiveTab(state,tab) {
            state.activeTab = tab;
        },
        setCurrentPerson(state,value) {
            state.userData.currentPerson = value
        },

        //setting mutations
        setVersion(state, version) {
            state.userData.settings.AIVersion = version;
        },
        setVersionDiff(state, version) {
            state.userData.settings.DiffVersion = version;
        },
        toggleVoiceReq(state, value) {
                state.userData.settings.voiceReq = value 
        },
        toggleVoiceAnswer(state, value) {
            state.userData.settings.voiceAnswer = value 
    },
        toggleImageGen(state, value) {
                state.userData.settings.imageGen = value 
        },
        changeImageRes(state, value) {
            state.userData.settings.imageRes = value 
        },
        changeImageCount(state, value) {
            state.userData.settings.imageCount = value 
        },
        changeImageStyle(state, value) {
            state.userData.settings.imageStyle = value 
        },
        toggleRefSettings(state,value) {
            state.userData.settings.refSettings.available = value 
        },
        toggleFamSettings(state,value) {
            state.userData.settings.familySharing = value 
        },
        setTimeRef(state,value) {
            state.userData.settings.refSettings.timeToSend = value;
        },
        toggleExchangeRate(state,value) {
            state.userData.settings.refSettings.exchangeRate = value 
        },
        toggleExchangeBitcoin(state,value) {
            state.userData.settings.refSettings.exchangeBitcoin = value 
        },
        toggleWeather(state,value) {
            state.userData.settings.refSettings.weather = value 
        },
        setPayRate(state, type) {
            let selectRate = state.AppSettings.shopPrices.findIndex(rate => rate.name === type)
            state.payRate = state.AppSettings.shopPrices[selectRate];
        },
        setLanguage(state, value) {
             state.userData.settings.language = value
             localStorage.setItem('lang', value.value)
        },
        fetchUserData(state, data) {
            let notFormatedChats = data.chatList
            let formatedChats = notFormatedChats.map((obj) => {
                return {'name': obj.chat_name, 'id': obj.id}
            })
            state.userData.chatList = formatedChats
            state.userData.avatar = data.image_url
            state.userData.name = data.name;
            state.userData.imageTokens = data.imageRequests
            state.userData.textTokens = data.textTokens 
            state.userData.settings.voiceAnswer = data.settings.voiceAnswer
            state.userData.settings.voiceReq = data.settings.voiceReq
            state.userData.settings.imageGen = data.settings.imageGen
            state.userData.settings.imageRes = data.settings.imageRes
            state.userData.settings.imageRes.name = data.settings.imageRes.value
            state.userData.settings.imageStyle = data.settings.DiffStyle
            state.userData.settings.imageCount = data.settings.imageCount
            state.userData.currentPerson = data.settings.Person
            state.userData.currentChat = data.settings.chat
            state.userData.subInfo = data.sub_info
            state.userData.personsList = [...state.userData.personsList,...data.customPersonsList]
            state.userData.currentPerson = state.userData.personsList.find(it => it.is_choose != null)
            state.userData.settings.refSettings.available = data.settings.refSettings.available
            state.userData.settings.refSettings.exchangeRate = data.settings.refSettings.exchangeRate
            state.userData.settings.refSettings.exchangeBitcoin = data.settings.refSettings.exchangeBitcoin
            state.userData.settings.refSettings.timeToSend = data.settings.refSettings.timeToSend
            state.userData.settings.refSettings.weather = data.settings.refSettings.weather
            state.userData.settings.imageStatDays = data.settings.img_stat_days
            state.userData.settings.imageStatValues = data.settings.img_stat_values
            state.userData.settings.tockensStatDays = data.settings.tokens_stat_days
            state.userData.settings.tockensStatValues = data.settings.tokens_stat_values
            state.userData.familyList = data.settings.familySharing
            state.userData.currentPerson = data.settings.Person
            state.userData.settings.AIVersion = data.settings.AIVersion
            state.userData.settings.language = data.settings.language
            state.userData.gpt4Tokens = data.gpt4Tokens
            state.userData.voiceRequests = data.voiceRequests
            state.userData.voiceOverRequests = data.voiceoverRequests
        },
        fetchAppData(state, data) {
            state.AppSettings.AIVersions = data.AIVersions;
            state.AppSettings.DiffVersions = data.DiffVersions;
            let notFormatedPersons = data.PersonsList;
            let formatedPersons = notFormatedPersons.map((obj) => {
                return {'name': obj.name_mode, 'mode_nubmer': obj.text_of_mode_number, 'editable' : false}
            })
            state.userData.personsList = [...state.userData.personsList, ...formatedPersons];
            state.AppSettings.imageRes = data.imageRes
            state.AppSettings.imageStyles = data.DiffStyles
            let notFormatedStyles = data.DiffStyles;
            let formatedStyles = notFormatedStyles.map((obj) => {
                return {'name': obj.name_ru,  "nameEn": obj.name_en}
            })
            state.AppSettings.imageStyles = formatedStyles
            state.AppSettings.shopPrices = data.shop_prices
        },
        setCurrentUserSettings(state,data) {
            state.userData.settings.DiffVersion = state.AppSettings.DiffVersions.find(item => item.ver_id === data.settings.DiffVersion.ver_id);
            state.userData.settings.AIVersion = state.AppSettings.AIVersions.find(item => item.ver_id === data.settings.AIVersion.ver_id);
        }

    },
    actions: {
       async getUserData({state, commit}) {
            if(state.userData.userID != null) {
                await axios.post(state.API_URL + '/GetUserData', {"id" : state.userData.userID, "username" : state.userData.name }, { //1483218909
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                }).then(function (response) {
                    commit('setLoading');
                    state.isAuth = true,
                    console.log(response.data.userData);
                    console.log(response.data.appData);
                    commit('fetchAppData', response.data.appData);
                    commit('fetchUserData', response.data.userData);
                    commit('setCurrentUserSettings', response.data.userData);
                    commit('setLanguage', response.data.userData.settings.language)
                    localStorage.setItem('lang', response.data.userData.settings.language.value);
                  }).catch(function (error) {
                    console.log(error);
                  });
            } else {
                commit('setLoading');
                router.push('auth')
            }
        },
        createNewChat({state}) {
            axios.post(state.API_URL + '/UpdateUserData', {
               "id" : state.userData.userID,
               "userData": { 
                   "AIVersion": state.userData.settings.AIVersion.ver_id,
                   "DiffStyle": state.userData.settings.imageStyle.name,
                   "DiffVersion": state.userData.settings.DiffVersion.ver_id,
                   "voiceAnswer": state.userData.settings.voiceAnswer,
                   "voiceReq": state.userData.settings.voiceReq, 
                   "imageCount": state.userData.settings.imageCount,
                   "imageGen": state.userData.settings.imageGen,
                   "imageRes": state.userData.settings.imageRes.value,
                   "person": state.userData.currentPerson.mode_nubmer ? state.userData.currentPerson.mode_nubmer : state.userData.currentPerson,
                   "personList": state.userData.personsList.filter(it => it.prompt != null),
                   "chat": state.userData.currentChat,
                   "del_chats": state.userData.chatList,
                   'family': state.userData.familyList,
                   'language' : state.userData.settings.language,
                   "refSettings": {
                    "available": state.userData.settings.refSettings.available,
                    "exchangeRate": state.userData.settings.refSettings.exchangeRate,
                    "exchangeBitcoin": state.userData.settings.refSettings.exchangeBitcoin,
                    "timeToSend": state.userData.settings.refSettings.timeToSend,
                    "weather": state.userData.settings.refSettings.weather,
                }
               },
               "start_new_chat": true
           }, {
               headers: {
                   "Content-type": "application/json; charset=UTF-8"
               }
           }).then(function (response) {
               console.log(response)
            tg.close();
             }).catch(function (error) {
               console.log(error);
             });
       },
       updateUserData({state}) {
        axios.post(state.API_URL + '/UpdateUserData', {
           "id" : state.userData.userID,
           "userData": { 
               "AIVersion": state.userData.settings.AIVersion.ver_id,
               "DiffStyle": state.userData.settings.imageStyle.name,
               "DiffVersion": state.userData.settings.DiffVersion.ver_id,
               "voiceAnswer": state.userData.settings.voiceAnswer,
               "voiceReq": state.userData.settings.voiceReq, 
               "imageCount": state.userData.settings.imageCount,
               "imageGen": state.userData.settings.imageGen,
               "imageRes": state.userData.settings.imageRes.value,
               "person": state.userData.currentPerson.mode_nubmer ? state.userData.currentPerson.mode_nubmer : state.userData.currentPerson,
               "personList": state.userData.personsList.filter(it => it.prompt != null),
               "chat": state.userData.currentChat,
               "del_chats": state.userData.chatList,
               'family': state.userData.familyList,
               'language' : state.userData.settings.language,
               "refSettings": {
                "available": state.userData.settings.refSettings.available,
                "exchangeRate": state.userData.settings.refSettings.exchangeRate,
                "exchangeBitcoin": state.userData.settings.refSettings.exchangeBitcoin,
                "timeToSend": state.userData.settings.refSettings.timeToSend,
                "weather": state.userData.settings.refSettings.weather,
            }
               
           },
           
           "start_new_chat": false
       }, {
           headers: {
               "Content-type": "application/json; charset=UTF-8"
           }
       }).then(function (response) {
           console.log(response)
           tg.close();
         }).catch(function (error) {
           console.log(error);
         });
   },

   newFamilyUser({state}) {
    axios.post(state.API_URL + '/Family', {
       "new_user_id" : state.userData.newFamilyUser,
       "user_id" : state.userData.userID
   }, {
       headers: {
           "Content-type": "application/json; charset=UTF-8"
       }
   }).then(function (response) {
       state.userData.familyList.push({"user_id": response.data.user_id, 'name': state.userData.newFamilyUser, 'status': response.data.status });
       if(response.data.status === 'user_not_found') {
        alert('Пользователь не найден')
        console.log('Пользователь не найден')
       }
     }).catch(function (error) {
        if(error.response.data.status === 'user_not_found') {
            alert('Пользователь не найден');
        console.log('Пользователь не найден');
     }
     console.log(error);}
     );
},
cancelSub({state}) {
    axios.post(state.API_URL + '/stop_sub', {
       "id" : state.userData.userID
   }, {
       headers: {
           "Content-type": "application/json; charset=UTF-8"
       }
   }).then(function () {
     }).catch(function (error) {
     console.log(error);}
     );
},

   
       
    },

})

