<template lang="">
    <div class="user-info__stats-card">
    <slot></slot>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss">
    .user-info__stats-card {
            padding: 9px 15px;
            border: 1px solid rgba(161, 161, 161, 0.40);
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            
            &--long {
                grid-column: span 2;
            }
    }
</style>