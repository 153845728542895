<template lang="">
    <div class="setting__item">
        <div class="setting__item-info">
            <slot></slot>
            <h4 class="setting__item-title">{{title}}</h4>
        </div>
        <div class="setting__item-time-input">
            <flat-pickr v-model="date" :config="config" ref="timePicker" @onChange="$store.commit('setTimeRef', date)"/>
        </div>
        <div class="setting__item-time" @click="openTimePicker">{{date}}</div>
    </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
    data() {
        return {
            date: this.active ? this.active : '-- : --',
            config:{
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
            }
        }
    },
    components: { flatPickr, },
    props: {
        icon: String,
        title: String,
        item: String,
        name: String,
        active: Boolean
    },
    emits: ['change'],
    methods: {
        change(status) {
            this.$emit('change', status)
        },
        openTimePicker() {

            let timePicker = this.$refs.timePicker;
            console.log(timePicker.fp.open)
            console.log(timePicker.fp.open());
        }
    }
}
</script>
<style scoped lang="scss">
     .setting__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 16px;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        position: relative;
        &:last-of-type {
            border-bottom: 0;
        }
        &-info{
            display: flex;
            align-items: center
        }
        &-title {
            @include text;
            color:var(--text-color);
        }
        &-choose {
            color:#A1A1A1
        }
        &-arrow {
            display: flex;
            align-content: center;
        }
        &-toggle {
            display: flex;
        }

        .setting__item-time-input {
            opacity: 0;
            position: absolute;
            right: 0;
        }
    }
</style>