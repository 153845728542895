<template lang="">
    <input type="checkbox" :checked="active" :name="name" :id="name" @change="$emit('change', $event.target.checked)"/><label :for="name">Toggle</label>
    {{status}}
</template>
<script>
export default {
    props: {
      name:String,
      active: Boolean
    },
}
</script>
<style scoped lang="scss">
input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 47px;
  height: 24px;
  background: #a1a1a1;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 3px;
  width: 20px;
  height: 20px;
  background: var(--bg-color);
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: var(--button-bg);
}

input:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}


</style>