<template lang="">
  <div class="setting__item" :class="{
    'setting__item--disable' : isActive === false
  }">
    <label :for="title">{{ title }}
    <input
      type="radio"
      :id="title"
      name="versionAI"
      v-model="select"
      :checked="checked"
      :value="item"
    />
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M18.3535 7.57359C18.1602 7.37638 17.8437 7.37321 17.6465 7.56651L9.83983 15.3731L6.3535 11.8868C6.15911 11.6963 5.84807 11.6963 5.65367 11.8868C5.45647 12.0801 5.4533 12.3966 5.64653 12.5938L9.48638 16.4336C9.58 16.5276 9.70726 16.5803 9.83989 16.5801C9.97252 16.5802 10.0997 16.5275 10.1933 16.4336L18.3535 8.27348C18.5441 8.07908 18.5441 7.76798 18.3535 7.57359Z" fill="#A1A1A1"/>
</svg>
</label>
  </div>
</template>
<script>
export default {
    data() {
        return {
            select: '',
            checked: false,
            isActive: true,
        }
    },
    props: {
        title: String,
        item: String,
        currentItem: String,
        active: Boolean,
        banned:Boolean,
    },
    mounted() {
        if(this.item === this.currentItem.name) {
            this.checked = true;
           }
        if(this.banned === true && this.$store.state.userData.subInfo.name === 'free') {
          this.isActive = false
        }
        if(!this.currentItem.name) {
          if(this.item === this.currentItem) {
            this.checked = true;
          }
        }

        
    },
    watch: {
        select() {
           this.$emit('change', this.select);
           if(this.item === this.currentItem.name) {
            this.checked = true;
           }
        }
    }

}
</script>
<style scoped lang="scss">
.setting__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  height: 46px;
  border-bottom: 0.5px solid rgba(161, 161, 161, 0.6);
  &:last-of-type {
    border-bottom: 0;
  }
  &-info {
    display: flex;
    align-items: center;
  }
  &-title {
    @include text;
    color: var(--text-color);
  }
  &--disable {
    opacity: 0.5;
    pointer-events: none;
  }
  input {
    display: none;
  }
  input:checked ~ svg{
    display: block;
  }
  svg{ 
    display: none;
  }
  label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
</style>
