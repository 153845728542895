<template lang="">
    <div class="nav">
        <div class="nav__list">
            <router-link to="/">
            <div class="nav__item">
                <div class="nav__item-icon">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 2C7 2 2.5 6.5 2.5 12C2.5 17.5 7 22 12.5 22C18 22 22.5 17.5 22.5 12C22.5 6.5 18 2 12.5 2ZM12.5 7C14.2 7 15.5 8.3 15.5 10C15.5 11.7 14.2 13 12.5 13C10.8 13 9.5 11.7 9.5 10C9.5 8.3 10.8 7 12.5 7ZM12.5 20C10.3 20 8.2 19.1 6.7 17.5C8.9 14.3 13.2 13.5 16.4 15.7C17.1 16.2 17.7 16.8 18.2 17.5C16.8 19.1 14.7 20 12.5 20Z" fill="#A1A1A1"/>
                    </svg>
                </div>
                <div class="nav__item-title">{{$t('profile')}}</div>
            </div>
        </router-link>
        <router-link to="/rates">
            <div class="nav__item">
                <div class="nav__item-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.7 11.8L12.2 2.3C12 2.1 11.8 2 11.5 2H5.9C5.4 2 4.9 2.2 4.5 2.6L2.6 4.5C2.2 4.9 2 5.4 2 5.9V11.1C2 11.6 2.2 12.1 2.6 12.5L11.8 21.7C12.2 22.1 12.8 22.1 13.2 21.7L21.7 13.2C22.1 12.8 22.1 12.2 21.7 11.8ZM7 8C6.4 8 6 7.6 6 7C6 6.4 6.4 6 7 6C7.6 6 8 6.4 8 7C8 7.6 7.6 8 7 8Z" fill="#A1A1A1"/>
                    </svg>
                </div>
                <div class="nav__item-title">{{$t('rates')}}</div>
            </div>
        </router-link>
        <router-link to="/settings">
            <div class="nav__item">
                <div class="nav__item-icon">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8 12.7C20.5 12.3 20.5 11.8 20.8 11.4L22.1 10C22.4 9.7 22.4 9.2 22.2 8.8L20.2 5.3C20 4.9 19.6 4.7 19.1 4.8L17.2 5.2C16.7 5.3 16.2 5 16.1 4.5L15.5 2.7C15.3 2.3 14.9 2 14.5 2H10.5C10.1 2 9.70001 2.3 9.60001 2.7L8.9 4.5C8.8 5 8.30001 5.3 7.80001 5.2L5.90001 4.8C5.50001 4.7 5.10001 4.9 4.80001 5.3L2.80001 8.8C2.60001 9.1 2.70001 9.6 3.00001 9.9L4.30001 11.3C4.60001 11.7 4.60001 12.2 4.30001 12.6L3.00001 14C2.70001 14.3 2.70001 14.8 2.90001 15.2L4.90001 18.7C5.10001 19.1 5.50001 19.3 6.00001 19.2L7.90001 18.8C8.40001 18.7 8.90001 19 9.00001 19.5L9.60001 21.3C9.70001 21.7 10.1 22 10.5 22H14.5C14.9 22 15.3 21.7 15.4 21.3L16 19.5C16.2 19 16.7 18.7 17.1 18.8L19 19.2C19.4 19.3 19.9 19.1 20.1 18.7L22.1 15.2C22.3 14.8 22.3 14.4 22 14L20.8 12.7ZM12.5 15C10.8 15 9.50001 13.7 9.50001 12C9.50001 10.3 10.8 9 12.5 9C14.2 9 15.5 10.3 15.5 12C15.5 13.7 14.2 15 12.5 15Z" fill="#A1A1A1"/>
                    </svg>
                </div>
                <div class="nav__item-title">{{$t('settings')}}</div>
            </div>
        </router-link>
        </div>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
export default {
    setup() {
        const { t } = useI18n({useScope:'global'})
        return {
        t
  }
    }
}
</script>
<style scoped lang="scss">
    .nav {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        background: var(--bg-color);
        padding: 7px 26px;
        z-index: 20;
        &__list {
            display: flex;
            justify-content: space-between;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            &--active {
                color: var(--button-bg);
            }
            &-title {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.24px;
            color:#A1A1A1;
            }
        }
    }

    a {
        text-decoration: none;
    }

    .router-link-active {
        .nav__item {
            .nav__item-title {
                color: var(--button-bg);
            }
            .nav__item-icon svg path{
                fill: var(--button-bg);
            }
           
        }
    }
</style>