<template lang="">
    <div class="search">
        <div class="search__wrapper" ref="searchBlock" @click="focusSearch">
            <div class="search__block">
            <div class="search__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.2163 17.6272L14.748 14.159C15.9356 12.8324 16.6646 11.0868 16.6667 9.16666C16.6667 5.02451 13.3088 1.66666 9.16667 1.66666C5.02452 1.66666 1.66667 5.02451 1.66667 9.16666C1.66667 13.3088 5.02452 16.6667 9.16667 16.6667C11.0868 16.6646 12.8324 15.9357 14.1589 14.7482L17.6271 18.2163C17.7885 18.3723 18.0445 18.3723 18.206 18.2163C18.3715 18.0565 18.3761 17.7927 18.2163 17.6272ZM9.16667 15.8333C5.48478 15.8333 2.50001 12.8486 2.50001 9.16666C2.50001 5.48476 5.48478 2.49999 9.16667 2.49999C12.8468 2.50421 15.8291 5.48649 15.8333 9.16666C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333Z" fill="#A1A1A1"/>
                </svg>
            </div>
            <input type="text" v-model="modelValue" @input="updateInput" class="search__input" :placeholder="$t('Search')" @focus="activateSearch()" ref="search">
        </div>
        </div>
        <div class="search__close" ref="closeSearch" @click="closeSearch()">
            <span>{{$t('Cancel')}}</span>
        </div>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
export default {
    data() {
        return {
            modelValue: '',
        }
    },
    methods: {
        activateSearch() {
           let closeSearch = this.$refs.closeSearch;
           let searchBlock = this.$refs.searchBlock;
           searchBlock.classList.add('search__wrapper--active')
           closeSearch.classList.add('search__close--active')
        },
        closeSearch() {
           let closeSearch = this.$refs.closeSearch;
           let searchBlock = this.$refs.searchBlock;
           searchBlock.classList.remove('search__wrapper--active')
           closeSearch.classList.remove('search__close--active')
           this.modelValue = '';
           this.$emit('resetSearch')
        },
        updateInput(event) {
             this.$emit('update:modelValue', event.target.value)
        },
        focusSearch() {
            this.$refs.search.focus();
        }
    },
    setup() {
        const { t, locale } = useI18n({useScope:'global'})
        return {
        t,locale
  }
    }
    
}
</script>
<style scoped lang="scss">
    .search {
        display: flex;
        align-items: center;
        overflow: hidden;
        &__close {
            color:#3179CB;
            font-weight: 400;
            width: 0;
            transition: 0.3s ease all;
            &--active {
                width: 30%;
                margin-left: 16px;
                transition: 0.3s ease all;
            }
        }
        &__wrapper {
        width: 100vw;
        background: var(--bg-color);
        padding: 6px 8px;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        &--active {
            justify-content: flex-start;
            .search__input {
                width: 100%;
            }
        }
        }
        &__block {
            display: flex;
            align-items: center;
        }
        &__icon {
            margin-right: 8px;
            display: flex;
            align-items: center;
        }
        &__input {
            background: var(--bg-color);
            outline: none;
            border: none;
            position: relative;
            width: 50px;
            color:white;
            font-size: 15px;
        }
        
    } 
</style>