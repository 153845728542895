<template>
    <header class="user-info">
    <img src="../assets/profilebg.svg" alt=""  class="user-info-bg">
    <user-avatar></user-avatar>
    <h1 class="user-info__name">{{ $store.state.userData.name }}</h1>
    <user-stats></user-stats>
    </header>
</template>
<script>
import userAvatar from "@/components/UI/userAvatar.vue"
import UserStats from '@/components/userStats.vue'
export default {
    components: { userAvatar, UserStats },
}
</script>
<style lang="scss" scoped>
    .user-info {
        background: var(--bg-color);
        display: flex;
        justify-content: center;
        margin-top: 63px;
        flex-direction: column;
        align-items: center;
        border-radius: 24px;
        padding: 16px;
        position: relative;

        &__name {
        @include h1;
        margin-top: 8px;
        margin-bottom: 20px;

    }

    .user-info-bg {
        position: absolute;
        right: 0;
        z-index: 1;
    }

    }

   
</style>