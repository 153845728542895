<template>
<user-info></user-info>
<user-bar></user-bar>
<modal-dialog v-if="$store.state.modalIsOpen" :title="$store.state.modalOptions.title" :hasInfo="$store.state.modalOptions.hasInfo">
    <delete-form v-if="$store.state.modalOptions.type === 'chat'" @deleteItem="$store.commit('deleteListItem')"></delete-form>
    <delete-form v-else-if="$store.state.modalOptions.type === 'deleteChats'" @deleteItem="$store.commit('deleteListItem')"></delete-form>
    <chatForm v-else-if="$store.state.modalOptions.type === 'editChat'" :chat="$store.state.userData.currentChat"></chatForm>
    <div v-else>
        <person-form @closeModal="closeModal" :person="$store.state.userData.currentPerson"></person-form>
    </div>
</modal-dialog>
</template>

<script>
import userInfo from '@/layouts/userInfo.vue';
import userBar from '@/layouts/userBar.vue';
import modalDialog from '@/components/UI/modalDialog.vue';
import personForm from '@/components/personForm.vue';
import deleteForm from '@/components/deleteForm.vue';
import chatForm from '@/components/chatForm.vue';

export default {
    data() {
        return {
            deleteItem: Boolean,
        }
    },
components: { userInfo, userBar, modalDialog, personForm, deleteForm, chatForm },
methods: {
    deleteItems() {
        console.log(this.deleteItem)
    }
}
}

</script>

<style lang="scss">
</style>