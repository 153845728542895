<template lang="">
    <button class="btn btn--gradient">
        <slot></slot>
    </button>
</template>
<script>
export default {
    
}
</script>
<style lang="scss">
    .btn {
        @include btn;
        z-index: 10;
    }
</style>