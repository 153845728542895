<template lang="">
    <setting-header :title="$t('settingFam')" :hasInfo="true" @showInfo="showInfoToggle"></setting-header>
    <div class="settings__list">
        <settingItemToggle :title="$t('settingFam')" :name="'ref-settings'" :active="$store.getters.getFamilySharing" @change="toggleFam">
            <img src="@/assets/icons/chat-bubble-user.svg" alt="" class="setting__item-icon">
        </settingItemToggle>
        <settingItemSelect :title="$t('familyInvite')" :name="'ref-settings'" :class="{
            'disabled' : $store.state.userData.settings.familySharing === false
        }" :active="$store.getters.getFamilySharing" @click="$store.commit('openModal')">
            <img src="@/assets/icons/message.svg" alt="" class="setting__item-icon">
        </settingItemSelect>
    </div>
    <family-list :items="$store.getters.getFamilyList"></family-list>
    <modalDialog v-if="showInfo && $store.state.modalIsOpen" :title="$t('infoTitle')" :hasInfo="false">
        <form class="person__info" @submit.prevent="closeInfo()">
       <p class="info__text">{{$t('familyInfo')}}</p>
        <button type="submit" class="btn btn--second" :class="{}">{{$t('icopy')}}</button>
        </form>
    </modalDialog>
    <modalDialog v-else-if="$store.state.modalIsOpen && !showInfo" :title="$t('addUser')" :hasInfo="false">
        <div v-if="$store.getters.getFamilyList.length === 3">
            <p  class="info__text">{{$t('FamilyError')}}</p>
            <button  class="btn btn--second" :class="{}" @click="$store.commit('closeModal')">{{$t('icopy')}}</button>
        </div>
        <form v-else class="person__info" @submit.prevent='addNewUser(personID)'>
        <formInput v-model="personID" :label="$t('AddUserID')" :name="'person-name'" :placeholder="$t('AddUserPlace')"></formInput>
        <button type="submit" class="btn btn--second" :class="{
            'disabled' : personID === ''
        }">{{$t('Save')}}</button>
        </form>
    </modalDialog>
    
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingHeader from '@/components/UI/settingHeader.vue';
import settingItemToggle from '@/components/settingItemToggle.vue';
import settingItemSelect from '@/components/settingItemSelect.vue';
import familyList from '@/components/Settings/familyList.vue';
import modalDialog from '@/components/UI/modalDialog.vue';
import formInput from '@/components/UI/formInput.vue';
export default {
    data() {
        return {
            personID: '',
            showInfo:false
        }
    },
    components: {settingHeader, settingItemToggle, settingItemSelect, familyList, modalDialog,formInput},
    methods: {
        addNewUser(id) {
            this.$store.commit('setFamilyUser', id);
            this.$store.dispatch('newFamilyUser');
        },
        toggleFam(value) {
        this.$store.commit('toggleFamSettings', value)
    },
    showInfoToggle() {
        this.showInfo = true;
        this.$store.commit('openModal')
    },
    closeInfo() {
        this.showInfo = false;
        this.$store.commit('closeModal')
        console.log()
    }
    },
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
     .settings__list {
        background: var(--bg-color);
        border-radius: 12px;
        margin-top: 20px;
        a {
        display: block;
        text-decoration: none;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        &:last-of-type {
        border-bottom: 0;
        }
        }
    }
    .setting__item-icon {
        margin-right: 8px;
    }

    button {
        margin-top: 24px;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .info__text {
        text-align: center;
        @include footnote;
    }
</style>