<template lang="">
    <setting-header :title="$t('Stats')"></setting-header>
    <chart-block></chart-block>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingHeader from '@/components/UI/settingHeader.vue';
import chartBlock from '@/components/Settings/chartBlock.vue';
export default {
    components: {settingHeader, chartBlock},
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .charts-list {
        margin-top: 24px;
    }
</style>