<template lang="">
    <div class="setting__item">
        <div class="setting__item-info">
            <slot></slot>
            <h4 class="setting__item-title">{{title}}</h4>
        </div>
        <div class="setting__item-info">
            <div class="setting__item-choose">
                {{item}}
            </div>
            <div class="setting__item-arrow" v-if="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M14.8535 11.6465L10.3535 7.1465C10.1597 6.9593 9.85254 6.9593 9.65875 7.1465C9.46014 7.33833 9.45465 7.65486 9.64648 7.85347L13.793 12L9.64648 16.1464C9.55273 16.2402 9.50006 16.3673 9.50006 16.4999C9.5 16.776 9.72388 16.9999 10 17C10.1326 17.0001 10.2598 16.9474 10.3535 16.8535L14.8535 12.3535C14.8534 12.3535 14.8535 12.3534 14.8535 12.3535C15.0486 12.1582 15.0487 11.8416 14.8535 11.6465Z" fill="#A1A1A1"/>
        </svg>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            iconLink: this.icon
        }
    },
    props: {
        icon: String,
        title: String,
        item: [Object, String],
        arrow: {
           type: Boolean,
           default: true,
        }
    },
}
</script>
<style scoped lang="scss">
    .setting__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 16px;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        &:last-of-type {
            border-bottom: 0;
        }
        &-info{
            display: flex;
            align-items: center
        }
        &-title {
            @include text;
            color:var(--text-color);
        }
        &-choose {
            color:#A1A1A1
        }
        &-arrow {
            display: flex;
            align-content: center;
        }
    }

</style>