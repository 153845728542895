<template lang="">
  <div class="family-item">
    <div class="family-item__name">{{ item.name }}</div>
    <div class="family-item__info">
      <span class="family-item__status" :class="{
        'active': this.item.status === 'in_family'
      }">{{userStatus}}</span>
      <button class="family-item__delete" @click="$store.commit('deleteFamilyItem', item)">
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="35"
            height="35"
            rx="7.5"
            stroke="#A1A1A1"
            stroke-opacity="0.4"
          />
          <path
            d="M26.5 12H24.5H22V10.5C21.9987 9.11981 20.8802 8.00128 19.5 8H16.5C15.1199 8.00146 14.0015 9.11987 14 10.5V12H11.5C11.4998 12 11.5002 12 11.5 12H9.5C9.22388 12 9 12.2239 9 12.5C9 12.7761 9.22388 13 9.5 13H11V25.5C11.0015 26.8801 12.1199 27.9985 13.5 28H22.5C23.8802 27.9987 24.9987 26.8802 25 25.5V13H26.5C26.7761 13 27 12.7761 27 12.5C27 12.2239 26.7761 12 26.5 12ZM15 10.5C15.0009 9.67194 15.6719 9.00092 16.5 9H19.5C20.3281 9.00092 20.9991 9.67194 21 10.5V12H15V10.5ZM24 25.5C23.9991 26.3281 23.3281 26.9991 22.5 27H13.5C12.6719 26.9991 12.0009 26.3281 12 25.5V13H14.5H21.5H24V25.5Z"
            fill="#A1A1A1"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        item: Object
    },
    computed: {
      userStatus() {
        if(this.item.status === 'user_invited' || this.item.status === 'inv') {
          return 'Приглашен';
        } else if(this.item.status === 'in_family') {
          return 'Присоединен'
        }
        return 'Не найден';
      }
    }
}
</script>
<style scoped lang="scss">
.family-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 16px;
  background: var(--bg-color);
  border-radius: 12px;
  padding: 9px 12px;
  margin-bottom: 8px;
}
.family-item__status {
  @include footnote;
  &.active {
    color:#8A53C2;
  }
}
.family-item__info {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  background: transparent;
  margin-left: 12px;
}
</style>
