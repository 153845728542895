<template lang="">
    <div class="user-bar">
        <tabList :variants='variants' :activeTab="$store.state.activeTab" @setActiveTab="setActiveTab"></tabList>
        <search-input v-model="searchQuery" @update:model-value="$store.commit('setSearchQuery', searchQuery)" @resetSearch="$store.commit('resetSearch')" class="user-bar__search" :activeTab="$store.state.activeTab"></search-input>
        <div v-if="$store.state.activeTab === 'chat'">   
        <personal-list  v-if="$store.getters.getChatsList.length !=0" :items="$store.getters.getChatsList" :type="$store.state.activeTab" @deleteItem="deleteItem" @deleteChats='deleteChats'></personal-list>
                <div v-else class="list-empty">{{$t('emptyChats')}}</div>
            </div> 
            <div v-else>
        <personal-list  v-if="$store.getters.getPersonsList !=0" :items="$store.getters.getPersonsList" @addNewPerson="addNewPerson" :type="$store.state.activeTab" @editPerson="editPerson"></personal-list>  
        <div v-else class="list-empty">Список личностей пуст</div>   
    </div>
    </div>
</template>
<script>
import tabList from '@/components/UI/tabList.vue';
import searchInput from '@/components/UI/searchInput.vue';
import personalList from '@/components/personalList.vue';
// Import Swiper Vue.js components
import { useI18n } from 'vue-i18n'

// Import Swiper styles
import 'swiper/css';

export default {
    data() {
        return {
            variants: [
                {name: 'chat', title: this.$t('selectChat')},
                {name: 'personality', title:this.$t('selectPerson')},
            ],
            searchQuery: '',
        }
    },
    components: {tabList, searchInput, personalList},
    methods: {
        setActiveTab(currentTab) {
            this.$store.commit('setActiveTab', currentTab)
        },
        deleteItem(item){
            this.$emit('deleteItem', item);
            this.$emit('openModal', {title: this.$t('youShure'), type:'chat', hasInfo: false});
            console.log(item);
        },
        addNewPerson() {
            this.$emit('addNewPerson');
            this.$emit('openModal', {title: this.$t('personSettings'), type:'person', hasInfo: true});
        },
        editPerson(item) {
            this.$emit('edit', item);
            this.$emit('openModal', {title: this.$t('personSettings'), type:'person', hasInfo: true});
        },
        deleteChats(){
            this.$emit('openModal', {title: this.$t('youShure'), type:'allChats', hasInfo: false});
        },
      
    },
    setup() {
        const { t, locale } = useI18n({useScope:'global'})
        return {
    t,locale
  }
    }
}
</script>
<style scoped lang="scss">
    .user-bar {
        margin-top: 24px;
        &__search {
            margin-top: 8px;
        }
    }
.list-empty {
    padding-top:80px;
    display: flex;
    justify-content: center;
}
</style>