<template lang="">
    <div class="settings__list">
        <settingItemToggle :title="$t('Reftitle')" :name="'ref-settings'" :active="$store.getters.getRefSettings.available" @change="toggleRef">
            <img src="@/assets/icons/newspaper.svg" alt="" class="setting__item-icon">
        </settingItemToggle>
        <div class="ref-settings" :class="{
            'disable' : $store.getters.getRefSettings.available === false
        }">
        <settingItemTime :title="$t('Timeref')" :name="'ref-time'" :active="$store.getters.getRefSettings.timeToSend" @change="toggleVoiceAnswer">
        <img src="@/assets/icons/clock.svg" alt="" class="setting__item-icon">
        </settingItemTime>
        <settingItemToggle :title="$t('exrate')" :name="'exchange-rate'" :active="$store.getters.getRefSettings.exchangeRate" @change="toggleRate">
            <img src="@/assets/icons/dollar-sign.svg" alt="" class="setting__item-icon">
        </settingItemToggle>
        <settingItemToggle :title="$t('bitcoinRate')" :name="'bitcoin-rate'" :active="$store.getters.getRefSettings.exchangeBitcoin" @change="toggleBitcoin">
            <img src="@/assets/icons/bitcoin-sign.svg" alt="" class="setting__item-icon">
        </settingItemToggle>
        </div>
    </div>
    <time-block></time-block>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingItemToggle from '../settingItemToggle.vue';
import settingItemTime from '../UI/settingItemTime.vue';
export default {
    components: {settingItemToggle, settingItemTime},
    methods: {
    toggleRef(value) {
        this.$store.commit('toggleRefSettings', value)
    },
    toggleRate(value) {
        this.$store.commit('toggleExchangeRate', value)
    },
    toggleBitcoin(value) {
        this.$store.commit('toggleExchangeBitcoin', value)
    },
    toggleWeather(value) {
        this.$store.commit('toggleWeather', value)
    },
},
setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }

}

</script>
<style scoped lang="scss">
    .settings__list {
        background: var(--bg-color);
        border-radius: 12px;
        margin-top: 20px;
        margin-bottom: 16px;
        a {
        display: block;
        text-decoration: none;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        &:last-of-type {
        border-bottom: 0;
        }
    }
}
    .setting__item-icon {
        margin-right: 8px;
    }

    .ref-settings.disable {
        opacity: 0.5;
        pointer-events: none;
    }
</style>