<template lang="">
    <div class="settings__list">
    <router-link to="versionAI">
        <settingItemSelect :title="$t('versionGPT')" :item="$store.state.userData.settings.AIVersion.name">
            <img src="@/assets/icons/robot.svg" alt="" class="setting__item-icon">
        </settingItemSelect>
    </router-link>
    <router-link to="versionDiff">
        <settingItemSelect :title="$t('versionDiff')" :item="$store.state.userData.settings.DiffVersion.name">
            <img src="@/assets/icons/image-plus.svg" alt="" class="setting__item-icon">
        </settingItemSelect>
    </router-link>
    <router-link to="imageStyle">
        <settingItemSelect v-if="locale === 'ru'" :title="$t('ImageGen')" :item="$store.getters.getImageStyle.name">
            <img src="@/assets/icons/scenery.svg" alt="" class="setting__item-icon">
        </settingItemSelect>
        <settingItemSelect v-else :title="$t('ImageGen')" :item="$store.getters.getImageStyle.nameEn">
            <img src="@/assets/icons/scenery.svg" alt="" class="setting__item-icon">
        </settingItemSelect>
    </router-link>
        <settingItemToggle :title="$t('voiceInput')" :class="{'disabled': onlyBetterPlan === true}" :name="'voice-requests'" :active="$store.getters.getVoiceReq" @change="toggleVoiceReq">
            <img src="@/assets/icons/microphone.svg" alt="" class="setting__item-icon">
        </settingItemToggle>
        <settingItemToggle :title="$t('voiceOutput')" :class="{'disabled': onlyBetterPlan === true}" :name="'voice-answers'" :active="$store.getters.getVoiceAnswer" @change="toggleVoiceAnswer">
            <img src="@/assets/icons/volume.svg" alt="" class="setting__item-icon">
        </settingItemToggle>
        <settingItemToggle :title="$t('ImageGen')" :name="'image-gen'" :active="$store.getters.getImageGen" @change="toggleImageGen">
            <img src="@/assets/icons/image.svg" alt="" class="setting__item-icon">
        </settingItemToggle>
        <router-link to="imageRes">
        <settingItemSelect :title="$t('ImageRes')" :item="$store.getters.getImageRes" :arrow="false">
            <img src="@/assets/icons/image-resize-square.svg" alt="" class="setting__item-icon">
        </settingItemSelect>
        </router-link>
        <router-link to="imageCount">
        <settingItemSelect :title="$t('ImageCount')" :item="$store.getters.getImageCount" :arrow="false">
            <img src="@/assets/icons/images.svg" alt="" class="setting__item-icon">
        </settingItemSelect>
    </router-link>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingItemSelect from '../settingItemSelect.vue';
import settingItemToggle from '../settingItemToggle.vue';
export default {
    data() {
        return {
            onlyBetterPlan: false,
            onlyPremiumPlan: false,
        }
    },
    components: {settingItemSelect,  settingItemToggle},
    methods: {
    toggleVoiceReq(value) {
        this.$store.commit('toggleVoiceReq', value)
    },
    toggleVoiceAnswer(value) {
        this.$store.commit('toggleVoiceAnswer', value)
    },
    toggleImageGen(value) {
        this.$store.commit('toggleImageGen', value)
    }
},
mounted() {
    if(this.$store.state.userData.subInfo.name === 'free') {
        this.onlyBetterPlan = true
        this.onlyPremiumPlan = true
    }
},
setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}

</script>
<style scoped lang="scss">
    .settings__list {
        background: var(--bg-color);
        border-radius: 12px;
        margin-top: 20px;
        margin-bottom: 16px;
        a {
        display: block;
        text-decoration: none;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        &:last-of-type {
        border-bottom: 0;
        }
    }
}
.setting__item:nth-of-type(3) {
    border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
}
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
    .setting__item-icon {
        margin-right: 8px;
    }
</style>