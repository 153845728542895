<template lang="">
    <div class="setting-page">
    <router-view>
    </router-view>
</div>
</template>
<script>
import 'animate.css';
export default {
    
}
</script>
<style scoped lang="scss">

</style>