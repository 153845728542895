<template lang="">
    <setting-header :title="$t('ImageRes')"></setting-header>
    <div class="settings__list">
        <settings-checkbox  v-for="resolution in resolutions" :key="resolution.name" :item="resolution.value" :currentItem="this.$store.state.userData.settings.imageRes" :title = "resolution.name"  @change="changeResolution(resolution)"></settings-checkbox>
    </div>
    <div>
        
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingHeader from '@/components/UI/settingHeader.vue';
import settingsCheckbox from '@/components/UI/settingsCheckbox.vue';
export default {
    components: {settingHeader, settingsCheckbox},
    computed: {
        resolutions() {
            return this.$store.state.AppSettings.imageRes
        },
    },
    methods: {
        isActive(res) {
           return this.$store.state.userData.settings.imageRes.value === res.value ? true : false
        },
        changeResolution(res) {
            this.$store.commit('changeImageRes', res);
        }
    },
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
     .settings__list {
        background: var(--bg-color);
        border-radius: 12px;
        margin-top: 20px;
        a {
        display: block;
        text-decoration: none;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        &:last-of-type {
        border-bottom: 0;
        }
        }
    }
    .setting__item-icon {
        margin-right: 8px;
    }
</style>