<template lang="">
    <div class="rates__header">
        <div class="rate__info">
            <h1 class="rate__info-title">{{getRateName}}</h1>
            <p class="rate__info-label">{{$t('yourRate')}}</p>
        </div>
        <div class="rate__stats">
            <div class="rate__stats-block">
                <h2 class="rate__stats-title" v-if="locale === 'ru' && rate.ru_str_date">
                   {{rate.ru_str_date}}
                </h2>
                <h2 v-else-if="rate.name === 'free'" class="rate__stats-title">
                   {{$t('Unlimited')}}
                </h2>
                <h2 v-else class="rate__stats-title">
                    {{rate.en_str_date}}
                </h2>
               
                <p class="rate__info-label">{{$t('timeRate')}}</p>
            </div>
            <div class="rate__stats-line"></div>
            <div class="rate__stats-block">
                <h2 v-if="locale === 'ru'" class="rate__stats-title">
                    {{rate.price.ru}} руб
                </h2>
                <h2 v-else class="rate__stats-title">
                    {{rate.price.en}} $
                </h2>
                <p class="rate__info-label">{{$t('price')}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
export default {
    props: {
        rate: Object
    },
    computed: {
        getRateName() {
            if(this.rate.name === 'start') {
                return this.$t('freePlan')
            } else if(this.rate.name === 'medium') {
                return this.$t('mediumPlan')
            }
            return this.$t('premiumPlan')
        }
    },
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .rates__header {
        background: var(--bg-color);
        padding: 24px 38px;
        width: 100%;
        border-radius: 24px;
        text-align: center;
        margin-top: 20px;
    }
    .rate {
        &__info {
            &-title {
                @include h1;
            }
            &-label {
                @include footnote
            }
        }
        &__stats {
            display: flex;
            justify-content: space-between;
            margin-top: 25px;
            position: relative;

            &-line {
                position: absolute;
                top: 0;
                left: 54%;
                width: 1px;
                transform: translateX(-50%);
                height: 100%;
                background: linear-gradient(180deg, rgba(145, 146, 144, 0.00) 0%, rgba(145, 146, 144, 0.60) 50.52%, rgba(145, 146, 144, 0.00) 100%);

            }
        }
    }
</style>