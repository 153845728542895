import { createRouter, createWebHistory } from "vue-router"
import ProfilePage from "@/pages/ProfilePage"
import RatesPage from "@/pages/RatesPage"
import SettingsPage from "@/pages/SettingsPage"
import SettingsLayout from "@/layouts/settingsLayout";

//settings
import settingsAI from "@/pages/Settings/settingsAI";
// settings AI
import settingsAIVersion from "@/pages/Settings/settingsAIVersion"
import settingsDiff from "@/pages/Settings/settingsDiff"
import imageRes from "@/pages/Settings/imageRes"
import imageStyle from "@/pages/Settings/imageStyle"
import imageCount from "@/pages/Settings/imageCount"
//settings report
import reportSettings from "@/pages/Settings/reportSettings"
//familyShare
import familyShare from "@/pages/Settings/familyShare"
//charts
import statsCharts from "@/pages/Settings/statsCharts"
//language
import languagePage from "@/pages/Settings/languagePage"
//terms
import termsPage from '@/pages/Settings/termsPage'
//privacy
import privacyPage from '@/pages/Settings/privacyPage'
//payment
import paymentPage from '@/pages/paymentPage'

import authPage from '@/pages/AuthPage'
const routes = [
    { path: '/', component: ProfilePage },
    { path: '/rates', component: RatesPage,},
    { path: '/auth', component: authPage,},
    {
      path: '/payment',
      component: paymentPage
    },
    { path: '/settings',  
    component: SettingsLayout,
    children: [
      {
        path: '',
        component: SettingsPage
      },
      {
        path: 'settingAI',
        component: settingsAI,
      },
      {
        path: 'versionAI',
        component:settingsAIVersion,
      },
      {
        path: 'versionDiff',
        component:settingsDiff,
      },
      {
        path: 'imageStyle',
        component:imageStyle,
      },
      {
        path: 'imageRes',
        component:imageRes,
      },
      {
      path: 'imageCount',
      component:imageCount,
    },
      {
        path: 'reportSettings',
        component:reportSettings ,
      },
      {
        path: 'familyShare',
        component:familyShare ,
      },
      {
        path: 'stats',
        component:statsCharts ,
      },
      {
        path: 'language',
        component:languagePage ,
      },
      {
        path: 'Terms',
        component:termsPage ,
      },
      {
        path: 'privacy',
        component:privacyPage ,
      }

    ]
  },
  ]


  const router = createRouter({
    routes,
    history: createWebHistory(),
  })
  
export default router