<template lang="">
    <div class="delete-buttons">
    <button class="btn btn--second btn--delete" @click="deleteChat">{{$t('yes')}}</button>
    <button class="btn btn--outline" @click="$store.commit('closeModal')">{{$t('no')}}</button>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
export default {
    methods: {
        deleteChat() {
            if(this.$store.state.modalOptions.type === 'deleteChats') {
                this.$store.commit('deleteAllChats')
                this.$store.commit('closeModal')
            } else {
                this.$store.commit('deleteListItem')
            }
        }
    },
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .btn--delete {
        margin-bottom:8px;
    }
</style>