<template lang="">
    <div class="user-info__stats">
        <stats-card class="user-info__stats-card--long">
        <div class="user-info__sub">
            <span class="user-info__sub-date" v-if="locale === 'ru'">{{$store.state.userData.subInfo.ru_str_date}}</span>
            <span class="user-info__sub-date" v-else>{{$store.state.userData.subInfo.en_str_date}}</span>
            <p class="user-info__sub-text">{{$t('endOfRate')}}</p>
        </div>
    </stats-card>
    <stats-card>
        <div class="user-info__text-token">
            <p class="user-info__sub-title">
                <span>GPT 3.5 </span> 
                <span class="divider"> |  </span> 
                    <span> Bard </span> </p>
            <span class="user-info__text-token-count">{{ $store.state.userData.textTokens.toLocaleString('ru-RU')}}</span>
            <p class="user-info__sub-text">{{$t('countTextTokens')}}</p>
        </div>
    </stats-card>
    <stats-card>
        <div class="user-info__text-token">
            <p class="user-info__sub-title">GPT 4</p>
            <span class="user-info__text-token-count">{{ $store.state.userData.gpt4Tokens.toLocaleString('ru-RU')}}</span>
            <p class="user-info__sub-text">{{$t('countTextTokens')}}</p>
        </div>
    </stats-card>
    <div class="stats__row">
        <stats-card>
        <div class="user-info__text-token">
            <span class="user-info__text-token-count">{{ $store.state.userData.imageTokens.toLocaleString('ru-RU')}}</span>
            <p class="user-info__sub-text">{{$t('countImageTokens')}}</p>
        </div>
    </stats-card>
    <stats-card>
        <div class="user-info__text-token">
            <span class="user-info__text-token-count">{{ $store.state.userData.voiceRequests.toLocaleString('ru-RU')}}</span>
            <p class="user-info__sub-text">{{$t('voiceTokensTitle')}}</p>
        </div>
    </stats-card>
    <stats-card>
        <div class="user-info__text-token">
            <span class="user-info__text-token-count">{{ $store.state.userData.voiceOverRequests.toLocaleString('ru-RU')}}</span>
            <p class="user-info__sub-text">{{$t('voiceOutTitle')}}</p>
        </div>
    </stats-card>
    </div>
    
    </div>
    <gradient-button class="user-info__btn" @click="$store.dispatch('createNewChat')">{{$t('newChat')}}</gradient-button>
    
</template>
<script>
import { useI18n } from 'vue-i18n'
import statsCard from '@/components/UI/statsCard.vue';
import gradientButton from '@/components/UI/gradientButton.vue';
export default {
    components: {statsCard,gradientButton},
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .user-info {
        &__sub {
        text-align: center;

        &-title {
            @include h3;
            margin-bottom: 8px;
        }

        &-date {
            @include h2;
        }
        &-text {
            @include footnote;
        }
        }

        &__text {
            &-token-count {
                @include h2;
            }
        }
        &__stats {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap:16px;
            width: 100%;
        }

        &__btn {
            margin-top:24px;
        }
    }

    .stats__row {
        display: flex;
        justify-content: space-between;
        grid-column: span 2; 
        gap: 14px;
    }

    .divider {
        margin: 0 3px;
    }
</style>