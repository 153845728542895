<template>
   <Line :data="chartData" :options="options" />
  </template>
  
  <script>
  import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LinearScale,
  LineElement,
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
  LinearScale,
  LineElement,
  CategoryScale,
  PointElement,
)

 
export default {
  name: 'App',
  components: {
    Line
  },
  props: {
    labels: Array,
    values: Array,
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
    plugins: {
      legend: false,
      tooltip:true,
      title: {
        display: true,
      }
    },
    datasets: [
      {
        label: 'Data One',
        data: this.values,
        cubicInterpolationMode: 'monotone',
        backgroundColor: '#8A53C2', 
        borderColor:'#8A53C2', 
      }
    ],
      },
      options: {
        responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          color: 'rgba(161, 161, 161, 0.50)'
        }
      },
      x: {
        grid: {
          color: 'transparent'
        }
      }
    },
      }
    }
  }
}
  </script>