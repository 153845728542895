<template lang="">
    <div class="family-list">
        <h2 class="family-list__item-title">{{$t('familyItems')}}</h2>
        <div class="family-items" v-if="items.length != 0 && $store.state.userData.settings.familySharing != false">
            <family-list-item  v-for="familyItem in items" :key="familyItem.id" :item="familyItem"></family-list-item>
        </div>
        <div class="family-empty" v-else>
            <p>{{$t('noneFamily')}}</p>
        </div>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import familyListItem from './familyListItem.vue';
export default {
    components: {familyListItem},
    props: {
        items:Array
    },
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
.family-list {
    margin-top: 24px;
}
    .family-list__item-title {
        text-align: center;
        @include h2;
        margin-bottom: 16px;
    }

    .family-empty {
        display: flex;
        align-content: center;
        justify-content: center;
        padding-top: 72px;
    }
</style>