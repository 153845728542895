<template lang="">
     <setting-header :title="$t('Terms')"></setting-header>
    <div class="settings__text" v-if="locale === 'ru'">
        <p>Перед использованием нашего Бота, ознакомьтесь с Правилами ниже, чтобы быть в курсе правил и ограничений. Если у вас есть конкретные вопросы относительно условий обслуживания Бота, или для получения дополнительной информации рекомендуется обращаться к Владельцу (разработчику) Бота по адресу тех.поддержки <a href="https://t.me/onazeron">https://t.me/onazeron</a></p>
    <ol>
    <li>Цель и функциональность. Бот предназначен для взаимодействия Пользователя на платной основе с сервисами:
        <ul>
    <li>ChatGPT 3.5 turbo</li>
    <li>GPT 4</li>
    <li>StableDiffusion XL 0.8</li>
    <li>StableDiffusion XL 1.0</li>
    <li>GoogleBard</li>
    <li>Whisper</li>
    <li>Llamaindex</li>
    <li>GoogleCloud TextToSpeech</li>
    </ul>
    </li>
    <li>
        Продолжительность предоставления Услуги для Пользователя (продолжительность доступа Пользователя к сервису Бота) указана на стартовой странице Бота. Начало срока предоставления услуги исчисляется с момента отражения факта оплаты Пользователем услуги в электронной системе учета платежей Владельца Бота, если иное не будет указано на странице покупки доступа к соответствующему сервису (Тарифу). Стороны признают и соглашаются, что неиспользование Пользователем предоставленного ему доступа к сервису, а также остановка или пауза не продлевают установленной Владельцем Бота продолжительности предоставления Услуги. Услуга считается оказанной в момент истечения оплаченного Пользователем периода доступа к сервису.
    </li>
    <li>Ограничения в использовании. 
        <p>Запрещены: неприемлемое поведение, нарушения авторских прав, спам и другие формы злоупотребления ботом. </p>
        <p>Под неприемлемым поведением понимаются:</p>
        <ul>
            <li>Использование оскорбительных или унизительных выражений в адрес бота.</li>
            <li>Отправка спама или повторяющихся сообщений</li>
            <li>Попытка сбить Бота с темы или задать неподходящие вопросы.</li>
            <li>Нахождение способов обмануть Бота или использовать его некорректно для получения нежелательной информации.</li>
            <li>Постоянное требование неподходящего или нереалистичного действия от Бота.</li>
            <li>Троллинг, например, задавание бессмысленных и грубых вопросов.</li>
            <li>Публикация или передача боту неподходящего или незаконного контента, например, жестокости или насилия.</li>
            <li>Непрекращающийся поток сообщений без какой-либо цели или смысла.</li>
            <li>Выявление и злоупотребление уязвимостей Бота или его для достижения личных целей.</li>
            <li>Несанкционированный вход в систему Бота или попытка его сломать или изменить.</li>
        </ul>
    </li>
    <li>Условия использования определенных функций.
            <p>4.1 Для чата с  ChatGPT 3.5 turbo и ChatGPT 4 используется система токенов: 1000 токенов являются эквивалентом приблизительно 350 русских или 750 английских слов. Токены снимаются с лицевого счета пользователя как за запрос (контекст чата), так и за ответ, чем длиннее запрос (в том числе контекст чата или ответ), тем больше будет списано токенов.</p>
            <p>4.2 В нашем Боте есть функция генерации изображений. За каждую генерацию изображений, с лицевого счета пользователя снимается 1 запрос из числа входящих в оплаченный пакет. В настройках можно выбрать количество генерируемых изображений по одному запросу (от 1 до 4), соответственно если пользователь выберет генерировать по 4 изображения за раз, то со счета снимутся 4 запроса.</p>
            <p>4.3 В связи с техническими особенностями, при выборе в качестве текстовой модели BARD, контекст чата, личности и генерация изображений отключаются до момента выбора пользователем другой модели.</p></li>
        <li>
        Интеллектуальная собственность. 
        <p>5.1	Наш Бот используется для предоставления информации и ответов на вопросы пользователей на основе доступной общедоступной информации и не обладает авторскими правами на контент, исходные данные или информацию, которую использует для формирования ответов. Бот не может гарантировать точность или полноту этой информации.</p>
        <p>5.2	Если бот, к которому обращается пользователь при посредстве нашего Бота, использует авторские права, товарные знаки или другие формы интеллектуальной собственности, условия их работы могут содержать положения об использовании этих прав и ограничения на их копирование или распространение.</p>
    </li>
    <li>Оплата 
    <p>6.1	Оплата Услуги осуществляется Пользователем на условиях 100% предварительной оплаты. Оплату Услуги невозможно произвести частями, используя несколько способов оплаты. При оплате услуги возможно взимание комиссии лицами, обеспечивающими осуществление платежей. При оплате услуги расходы на осуществление платежей несет Пользователь. Пользователь настоящим дает согласие на оплату комиссий третьим лицам, обеспечивающим осуществление платежей за услуги. </p>
    <p>6.2	Любой платеж, совершенный от имени Пользователя и/или с использованием платежных реквизитов/средств Пользователя, считается произведенным самим Пользователем. </p>
    <p>6.3	В случае отказа Пользователя от услуги, в том числе путем удаления аккаунта Пользователя, денежные средства Пользователю не возвращаются. Удержанные Владельцем Бота денежные средства признаются Сторонами компенсацией фактически понесенных им  расходов на оказание услуги, в т.ч. на формирование и поддержание работоспособности Бота. Стороны признают, что указанный размер компенсации является соразмерным понесенным Владельцем Бота расходам и не подлежит доказыванию в случае возникновения каких-либо споров.</p>
</li>
    <li>
        Оплата банковской картой онлайн
        <p>Наш Бот подключен к интернет-эквайрингу, и Вы можете оплатить Услуги банковской картой Visa, MasterCard и МИР. После подтверждения выбранного Тарифа откроется защищенное окно с платежной страницей процессингового центра IntellectMoney, где Вам необходимо ввести данные Вашей банковской карты. </p>
        <p>Если Ваш банк поддерживает данную технологию, Вы будете перенаправлены на его сервер для дополнительной идентификации. Информацию о правилах и методах дополнительной идентификации Вы можете уточнить в банке, выдавшем Вам банковскую карту. </p>
    </li>
    <li>Гарантии безопасности платежа
        <p>Процессинговый центр IntellectMoney защищает и обрабатывает данные банковской карты Пользователя по стандарту безопасности PCI DSS 3.2. Передача информации в платежный шлюз происходит с применением технологии шифрования SSL. Дальнейшая передача информации происходит на стороне банка по закрытым каналам. IntellectMoney не передает данные карты Владельцу Бота и иным лицам. Для дополнительной аутентификации держателя карты используется протокол 3D Secure.</p>
        <p>8.1	В случае, если у Вас есть вопросы по совершенному платежу, Вы можете обратиться в службу поддержки клиентов платежного сервиса по электронной почте info@intellectmoney.ru.</p>
    </li>
    <li>
       Безопасность онлайн-платежей
<p>9.1	Предоставляемая Пользователем персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является конфиденциальной и не подлежит разглашению. Данные карты Пользователя передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.</p>
<p>9.2	Безопасность обработки Интернет-платежей гарантирует IntellectMoney. Все операции с платежными картами происходят в соответствии с требованиями VISA International, MasterCard и других платежных систем. При передаче информации используются специальные технологии безопасности карточных онлайн-платежей, обработка данных ведется на защищенном сервере процессинговой компании.
</p> </li>
<li>
   Ограничения ответственности.
   <p>10.1.	Владелец Бота не несет ответственности за несоответствие полученных материалов ожиданиям Пользователя и/или за его субъективную оценку, такое несоответствие ожиданиям и/или отрицательная субъективная оценка не являются основаниями считать услуги оказанными некачественно.
</p>
<p>10.2.	Владелец Бота не несет ответственности за ошибки в работе Бота, а также за несанкционированные доступы или атаки третьих лиц, за невозможность оказания Услуг, если такая невозможность возникла вследствие нарушения работы сети Интернет, программного обеспечения, оборудования Пользователя, перерывы в работе Интернет-ресурсов и вызванную ими потерю информации.
</p>
<p>10.3.	Владелец Бота вправе заблокировать Пользователю доступ к Боту и/или услугам (в том числе оплаченным) в случае нарушения Пользователем настоящих условий,  а также в случае, если Владелец Бота сочтет действия Пользователя незаконными, мошенническими или направленными на порчу Бота или подрыв его репутации, организацию DDoS-атаки, проведение фишинговой атаки, попытку сканирования и взлома и т.п. При этом денежные средства, перечисленные Пользователем за услуги, возврату не подлежат.
</p>
</li>
<li>В случае, если Пользователь по причинам, не зависящим от Владельца Бота, не использовал предоставленный ему объем прав пользователя в течение срока, предусмотренного Условиями выбранного Тарифа, то услуга считается оказанной надлежащим образом и оплаченные Владельцу Бота денежные средства возврату не подлежат.
</li>
<li>
   Изменения и расторжение Условий. Владелец (разработчик) Бота может вносить изменения в настоящие Условия в любое время. Обязательства, возникшие до внесения изменений в Условия, исполняются в том виде и объёме, в каком они существовали на день оплаты.</li>
</ol>
<p>Контактные данные. </p>
<p>Индивидуальный предприниматель  Куканянц Вазген Карабедович</p>
<p>Адрес для корреспонденции: 398001, г. Липецк пл.Петра Великого д.3 офис 508</p>
<p>ИНН 502482591522, ОГРНИП 320482700033722</p>
<a href="https://t.me/onazeron">https://t.me/onazeron</a>

    </div>
    <div class="settings__text" v-else>
<p>Before using our Bot, read the Rules below to be aware of the rules and restrictions. If you have specific questions regarding the terms of service of the Bot, or for more information, it is recommended to contact the Owner (developer) of the Bot at tech.supports <a href="https://t.me/onazeron ">https://t.me/onazeron </a></p>
<ol>
<li>Purpose and functionality. The bot is designed for User interaction on a paid basis with services:
<ul>
<li>ChatGPT 3.5 turbo</li>
<li>GPT 4</li>
<li>StableDiffusion XL 0.8</li>
<li>StableDiffusion XL 1.0</li>
<li>GoogleBard</li>
<li>Whisper</li>
<li>Llamaindex</li>
<li>GoogleCloud TextToSpeech</li>
</ul>
</li>
<li>
The duration of the Service provision for the User (the duration of the User's access to the Bot service) is indicated on the Bot's home page. The beginning of the service provision period is calculated from the moment when the User's payment for the service is reflected in the electronic payment accounting system of the Bot Owner, unless otherwise indicated on the page for purchasing access to the corresponding service (Tariff). The Parties acknowledge and agree that the User's non-use of the access to the service provided to him, as well as a stop or pause, do not extend the duration of the Service provided by the Bot Owner. The Service is considered rendered at the time of expiration of the period of access to the service paid by the User.
</li>
<li>Restrictions in use.
<p>Prohibited: unacceptable behavior, copyright infringement, spam and other forms of bot abuse. </p>
<p>Unacceptable behavior means:</p>
<ul>
<li>The use of offensive or humiliating expressions to the bot.</li>
<li>Sending spam or duplicate messages</li>
<li>An attempt to knock the Bot off the topic or ask inappropriate questions.</li>
<li>Finding ways to trick the Bot or use it incorrectly to obtain unwanted information.</li>
<li>Constant demand for inappropriate or unrealistic action from the Bot.</li>
<li>Trolling, for example, asking meaningless and rude questions.</li>
<li>Publishing or transmitting inappropriate or illegal content to the bot, for example, cruelty or violence.</li>
<li>An incessant stream of messages without any purpose or meaning.</li>
<li>Identifying and abusing the vulnerabilities of the Bot or its to achieve personal goals.</li>
<li>Unauthorized entry into the Bot's system or an attempt to break or change it.</li>
</ul>
</li>
<li>Terms of use of certain functions.
<p>4.1 For chat with ChatGPT 3.5 turbo and ChatGPT 4, a token system is used: 1000 tokens are the equivalent of approximately 350 Russian or 750 English words. Tokens are withdrawn from the user's personal account both for the request (chat context) and for the response, the longer the request (including the chat context or response), the more tokens will be debited.</p>
<p>4.2 Our Bot has an image generation function. For each generation of images, 1 request from the number included in the paid package is withdrawn from the user's personal account. In the settings, you can select the number of generated images per request (from 1 to 4), respectively, if the user chooses to generate 4 images at a time, then 4 requests will be deducted from the account.</p>
<p>4.3 Due to technical features, when choosing BARD as a text model, the chat context, personality and image generation are disabled until the user selects another model.</p></li>
<li>
Intellectual property.
<p>5.1 Our Bot is used to provide information and answers to users' questions based on publicly available information and does not own copyrights to the content, source data or information that it uses to form answers. The bot cannot guarantee the accuracy or completeness of this information.</p>
<p>5.2 If the bot accessed by the user through our Bot uses copyrights, trademarks or other forms of intellectual property, the terms of their work may contain provisions on the use of these rights and restrictions on their copying or distribution.</p>
</li>
<li>Payment
    <p>6.1 Payment for the Service is carried out by the User on the terms of 100% prepayment. Payment for the Service cannot be made in parts using several payment methods. When paying for the service, it is possible to charge a commission by persons providing payments. When paying for the service, the costs of making payments are borne by the User. The User hereby agrees to pay commissions to third parties providing payments for services. </p>
    <p>6.2 Any payment made on behalf of the User and/or using the User's payment details/funds is considered to be made by the User himself. </p>
    <p>6.3 If the User refuses the service, including by deleting the User's account, the funds will not be returned to the User. The funds withheld by the Owner of the Bot are recognized by the Parties as compensation for the expenses actually incurred by him for the provision of services, including for the formation and maintenance of the Bot's operability. The Parties acknowledge that the specified amount of compensation is commensurate with the expenses incurred by the Bot Owner and is not subject to proof in the event of any disputes.</p>
</li>
<li>
Payment by bank card online
<p>Our Bot is connected to Internet acquiring, and you can pay for Services with a Visa, MasterCard and MIR bank card. After confirming the selected Tariff, a secure window will open with the payment page of the IntellectMoney processing center, where you need to enter your bank card details. </p>
<p>If your bank supports this technology, you will be redirected to its server for additional identification. You can check the information about the rules and methods of additional identification with the bank that issued you a bank card. </p>
</li>
<li>Payment security guarantees
<p>The IntellectMoney processing center protects and processes the User's bank card data according to the PCI DSS 3.2 security standard. Information is transmitted to the payment gateway using SSL encryption technology. Further transmission of information takes place on the bank's side through closed channels. IntellectMoney does not transfer card data to the Bot Owner and other persons. For additional authentication of the cardholder, the 3D Secure protocol is used.</p>
<p>8.1 If you have any questions about the completed payment, you can contact the customer support service of the payment service by e-mail info@intellectmoney.ru .</p>
</li>
<li>
Online payment Security
<p>9.1 The personal information provided by the User (name, address, phone, e-mail, bank card number) is confidential and is not subject to disclosure. The User's card data is transmitted only in encrypted form and is not stored on our Web server.</p>
<p>9.2 The security of online payment processing is guaranteed by IntellectMoney. All transactions with payment cards take place in accordance with the requirements of VISA International, MasterCard and other payment systems. When transmitting information, special security technologies for online card payments are used, data processing is carried out on a secure server of the processing company.
</p> </li>
<li>
Limitations of liability.
<p>10.1. The Bot owner is not responsible for the inconsistency of the received materials with the User's expectations and/or for his subjective assessment, such inconsistency with expectations and/ or a negative subjective assessment are not grounds to consider the services rendered poorly.
</p>
<p>10.2. The owner of the Bot is not responsible for errors in the operation of the Bot, as well as for unauthorized access or attacks by third parties, for the inability to provide Services, if such an impossibility arose due to disruption of the Internet, software, User equipment, interruptions in the operation of Internet resources and the loss of information caused by them.
</p>
<p>10.3. The Bot Owner has the right to block the User's access to the Bot and/or services (including paid ones) in case the User violates these terms, as well as if the Bot Owner considers the User's actions illegal, fraudulent or aimed at damaging the Bot or undermining its reputation, organizing a DDoS attack, conducting a phishing attack, an attempt to scan and hack, etc. At the same time, the funds transferred by the User for services are not subject to refund.
</p>
</li>
<li>If the User, for reasons beyond the control of the Bot Owner, did not use the amount of user rights granted to him during the period stipulated by the Terms of the selected Tariff, the service is considered to have been properly rendered and the funds paid to the Bot Owner are not refundable.
</li>
<li>
Changes and termination of the Terms. Owner (developer) Bota may make changes to these Terms at any time. Obligations that arose before changes were made to the Terms and Conditions are fulfilled in the form and volume in which they existed on the day of payment.</li>
</ol>
<p>Contact details. </p>
<p>Individual entrepreneur Kukanyants Vazgen Karabedovich</p>
<p>Correspondence address: 398001, Lipetsk, Peter the Great Square, 3 office 508</p>
<p>TIN 502482591522, OGRNIP 320482700033722</p>
<a href="https://t.me/onazeron">https://t.me/onazeron</a>

</div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingHeader from '@/components/UI/settingHeader.vue';
export default {
    components: {settingHeader},
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .settings__text {
        margin-top: 28px;
        font-weight: 400;
        line-height: 24px;
        a {
            color: white;
        }
        ol {
            padding-left: 16px;
            margin: 20px 0;
            li {
                margin: 15px 0;
            }
        }

    }

    p {
        margin: 20px 0;
    }
</style>