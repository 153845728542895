<template lang="">
  <div class="list__items">
    <personal-list-item v-for="item in items" :item="item" :checked="type === 'chat' ? select === item : select === item.name" :key="item.name" :type="type" @change="setCurrentItemList"></personal-list-item>
    <button
      v-if="type === 'chat'"
      class="btn btn--default list__delete-all"
      @click="deleteChats"
    >
      <div class="btn__icon">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.5 6.5H18.5H16V5C15.9987 3.61981 14.8802 2.50128 13.5 2.5H10.5C9.11987 2.50146 8.00146 3.61987 8 5V6.5H5.5C5.49982 6.5 5.50024 6.5 5.5 6.5H3.5C3.22388 6.5 3 6.72388 3 7C3 7.27612 3.22388 7.5 3.5 7.5H5V20C5.00146 21.3801 6.11987 22.4985 7.5 22.5H16.5C17.8802 22.4987 18.9987 21.3802 19 20V7.5H20.5C20.7761 7.5 21 7.27612 21 7C21 6.72388 20.7761 6.5 20.5 6.5ZM9 5C9.00092 4.17194 9.67194 3.50092 10.5 3.5H13.5C14.3281 3.50092 14.9991 4.17194 15 5V6.5H9V5ZM18 20C17.9991 20.8281 17.3281 21.4991 16.5 21.5H7.5C6.67194 21.4991 6.00092 20.8281 6 20V7.5H8.5H15.5H18V20Z"
            fill="#A1A1A1"
          />
        </svg>
      </div>
      {{$t('deleteChats')}}
    </button>
    <button
      v-else
      class="btn btn--default list__create"
      @click="addNewPerson()"
    >
      <div class="btn__icon">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.9747 11H20.4747V9.5C20.4747 9.22388 20.2509 9 19.9747 9C19.6986 9 19.4747 9.22388 19.4747 9.5V11H17.9747C17.6986 11 17.4747 11.2239 17.4747 11.5C17.4747 11.7761 17.6986 12 17.9747 12H19.4747V13.5C19.4747 13.4998 19.4747 13.5002 19.4747 13.5C19.4749 13.776 19.6987 14.0002 19.9747 14C19.9745 14 19.9749 14 19.9747 14C20.2507 13.9998 20.4749 13.776 20.4747 13.5V12H21.9747C22.2509 12 22.4747 11.7761 22.4747 11.5C22.4747 11.2239 22.2509 11 21.9747 11ZM13.354 12.8697C14.9066 12.0211 15.9725 10.3936 15.9747 8.5C15.9747 5.73859 13.7362 3.5 10.9747 3.5C8.21332 3.5 5.97474 5.73859 5.97474 8.5C5.97474 10.3936 7.0398 12.0214 8.59186 12.8701C5.53303 13.8275 3.22394 16.5767 3.00111 19.9668C2.98292 20.2424 3.19141 20.4805 3.46693 20.499C3.47956 20.5004 3.49219 20.5012 3.50489 20.5015C3.7707 20.5087 3.99201 20.299 3.99915 20.0332C4.22871 16.5328 7.01691 13.7445 10.5173 13.515C14.3699 13.2624 17.6978 16.1807 17.9503 20.0332C17.9681 20.2958 18.1862 20.4998 18.4493 20.5C18.4601 20.5 18.4708 20.5 18.4826 20.499C18.7581 20.4805 18.9666 20.2424 18.9484 19.9668C18.7278 16.6099 16.4453 13.8337 13.354 12.8697ZM10.9747 12.5C8.76557 12.5 6.97474 10.7092 6.97474 8.5C6.97474 6.29083 8.76557 4.5 10.9747 4.5C13.183 4.5022 14.9725 6.29175 14.9747 8.5C14.9747 10.7092 13.1839 12.5 10.9747 12.5Z"
            fill="#A1A1A1"
          />
        </svg>
      </div>
      {{$t('createPerson')}}
    </button>
  </div>
</template>
<script>
import personalListItem from './personalListItem.vue';
import { useI18n } from 'vue-i18n'
export default {
  data() {
    return {
      select:'',
    }
  },
  name: "personalList",
  components: { personalListItem },
  props: {
    items: Array,
    type: String,
  },
  methods: {
    addNewPerson() {
      this.$store.commit('openModal', {title: this.t('personSettings'), type:'Person', hasInfo: true});
      this.$store.commit('resetPerson');
    },
    editPerson(item) {
      this.$emit("editPerson", item);
    },
    setCurrentItemList(item) {
      if(this.type === 'chat') {
        this.$store.commit('setCurrentItemList', {'name': item.name, 'id' : item.id,  'type' : this.type})
      } else {
        this.$store.commit('setCurrentItemList', {'name': item,  'type' : this.type})
      }
     this.select = item;
    },
    deleteChats() {
      this.$store.commit('openModal', {title: this.t('youShure'), type:'deleteChats', hasInfo: false});
    }
  },

   setup() {
        const { t, locale } = useI18n({useScope:'global'})
        return {
        t,locale
  }
    }

};
</script>
<style scoped lang="scss">
.list {
  &__items {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
  }
}
</style>
