<template lang="">
    <div class="charts-list">
    <div class="chart__block">
        <div class="chart__block-header">
            <h3 class="chart__block-title">{{$t('TokensStats')}}</h3>
            <span v-if="locale === 'ru'" class="chart__block-dates">{{this.$store.state.userData.settings.tockensStatDays.ru[0]}} - {{this.$store.state.userData.settings.tockensStatDays.ru[this.$store.state.userData.settings.tockensStatDays.ru.length - 1]}}</span>
            <span v-else class="chart__block-dates">{{this.$store.state.userData.settings.tockensStatDays.en[0]}} - {{this.$store.state.userData.settings.tockensStatDays.en[this.$store.state.userData.settings.tockensStatDays.en.length - 1]}}</span>  
        </div>
        <div class="chart__block-graph">
            <chartVue v-if="locale === 'ru'" :labels="this.$store.state.userData.settings.tockensStatDays.ru" :values="this.$store.state.userData.settings.tockensStatValues"></chartVue>
            <chartVue v-else :labels="this.$store.state.userData.settings.tockensStatDays.en" :values="this.$store.state.userData.settings.tockensStatValues"></chartVue>
        </div>
    </div>
    <div class="chart__block">
        <div class="chart__block-header">
            <h3 class="chart__block-title">{{$t('ImageStats')}}</h3>
            <span v-if="locale === 'ru'" class="chart__block-dates">{{this.$store.state.userData.settings.imageStatDays.ru[0]}} - {{this.$store.state.userData.settings.imageStatDays.ru[this.$store.state.userData.settings.imageStatDays.ru.length - 1]}}</span> 
            <span v-else class="chart__block-dates">{{this.$store.state.userData.settings.imageStatDays.en[0]}} - {{this.$store.state.userData.settings.imageStatDays.en[this.$store.state.userData.settings.imageStatDays.en.length - 1]}}</span> 
        </div>
        <div class="chart__block-graph">
            <chartVue  v-if="locale === 'ru'" :labels="this.$store.state.userData.settings.imageStatDays.ru" :values="this.$store.state.userData.settings.imageStatValues"></chartVue>
            <chartVue  v-else :labels="this.$store.state.userData.settings.imageStatDays.en" :values="this.$store.state.userData.settings.imageStatValues"></chartVue>
        </div>
    </div>
</div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import chartVue from '../UI/chartVue.vue';
export default {
    components: {chartVue},
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .chart__block {
        background: var(--bg-color);
        padding: 16px;
        border-radius: 24px;
        margin-bottom: 16px;

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-title {
            @include h3;
        }
        &-dates {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color:#A1A1A1
        }
        &-graph {
            margin-top: 8px;
        }
    }
</style>