<template lang="">
    <div class="setting__item">
        <div class="setting__item-info">
            <slot></slot>
            <h4 class="setting__item-title">{{title}}</h4>
        </div>
        <div class="setting__item-toggle">
            <buttonToggle :name="name" :active="active" @change="change"></buttonToggle>
        </div>
    </div>
</template>
<script>
import buttonToggle from './UI/buttonToggle.vue';
export default {
    data() {
        return {
            data: '',
        }
    },
    components: {buttonToggle},
    props: {
        icon: String,
        title: String,
        item: String,
        name: String,
        active: Boolean
    },
    emits: ['change'],
    methods: {
        change(status) {
            this.$emit('change', status)
        }
    }
}
</script>
<style scoped lang="scss">
     .setting__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 16px;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        &:last-of-type {
            border-bottom: 0;
        }
        &-info{
            display: flex;
            align-items: center
        }
        &-title {
            @include text;
            color:var(--text-color);
        }
        &-choose {
            color:#A1A1A1
        }
        &-arrow {
            display: flex;
            align-content: center;
        }
        &-toggle {
            display: flex;
        }
    }
</style>