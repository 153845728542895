<template lang="">
    <div class="settings__list">
      <router-link to="settings/settingAI">
        <settingItem  :title = "$t('settingAI')">
           <img src="../assets/brain.svg" alt="" class="setting__item-icon">
        </settingItem>
    </router-link>
    <router-link to="settings/reportSettings" :class="{'disabled': onlyBetterPlan === true}">
        <settingItem :title = "$t('settingRef')">
            <img src="../assets/newspaper.svg" alt="" class="setting__item-icon">
        </settingItem>
    </router-link>
    <router-link to="settings/familyShare" :class="{'disabled': onlyBetterPlan === true}">
        <settingItem :title = "$t('settingFam')">
            <img src="../assets/chat-bubble-user.svg" alt="" class="setting__item-icon">
        </settingItem>
    </router-link>
    <router-link to="settings/stats">
        <settingItem :title = "$t('Stats')"> 
            <img src="../assets/chart-line.svg" alt="" class="setting__item-icon">
        </settingItem>
    </router-link>
    <router-link to="settings/language">
        <settingItem :title = "$t('language')" >
            <img src="../assets/globe.svg" alt="" class="setting__item-icon">
        </settingItem>
    </router-link>
        <settingItem :title = "$t('cancel')" @click="this.$store.dispatch('cancelSub')" class="menu-item">
            <img src="../assets/circle.svg" alt="" class="setting__item-icon">
        </settingItem>
    <router-link to="settings/terms">
        <settingItem :title = "$t('Terms')" >
            <img src="../assets/shield-check.svg" alt="" class="setting__item-icon">
        </settingItem>
    </router-link>
    <router-link to="settings/privacy">
        <settingItem :title = "$t('Privacy')">
            <img src="../assets/lock.svg" alt="" class="setting__item-icon">
        </settingItem>
    </router-link>
    <a href="https://t.me/onazeron" class="last-item">
        <settingItem :title = "$t('Feedback')">
            <img src="../assets/comment.svg" alt="" class="setting__item-icon">
        </settingItem>
    </a>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingItem from './settingItem.vue';
export default {
    data() {
        return {
            onlyBetterPlan: false,
            onlyPremiumPlan: false
        }
    },
    components:{ settingItem },
    mounted() {
    if(this.$store.state.userData.subInfo.name === 'free') {
        this.onlyBetterPlan = true
        this.onlyPremiumPlan = true
    }
},
setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .settings__list {
        background: var(--bg-color);
        border-radius: 12px;
        margin-top: 20px;
        a {
        display: block;
        text-decoration: none;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        }
    }
    .setting__item-icon {
        margin-right: 8px;
    }
    .disabled {
    opacity: 0.5;
    pointer-events: none;
}
.menu-item {
    border-bottom: 0.5px solid rgba(161, 161, 161, 0.60)!important;
}

.last-item {
    border-bottom: 0!important;
}
</style>