<template lang="">
    <div class="payment">
        <div class="payment__info">
            <img src="../assets/moneylogo.png" alt="payment_logo">
            <div class="payment__content">
                <h3 class="payment__title">Intellect Money</h3>
                <p class="payment__label">{{$t('enterPay')}}</p>
            </div>
        </div>
    </div>
    <div class="payment__form">
                <form target="_blank" v-if="locale === 'ru'" action='https://merchant.intellectmoney.ru/ru/' enctype="application/x-www-form-urlencoded">
                    <input id='eshopId' type='hidden' :value='465750' name='eshopId'/>
                    <input id='orderId' type='hidden' :value='$store.getters.getUserID' name='orderId'/>
                    <input id='serviceName' type='hidden' :value='"Оплата тарифа" + $store.getters.getPayRate.name' name='serviceName'/>
                    <input id='recipientAmount' type='hidden' :value='$store.getters.getPayRate.price.ru' name='recipientAmount'/>
                    <input type='hidden' value='RUB' name='recipientCurrency'/>
                    <input id='recurringType' type='hidden' value='Activate' name='recurringType'/>
                    <input type='hidden' name='hash' :value="hash" />
                    <input type=submit :value="$t('PaymentButton')"  class="btn btn--gradient" /><br/>
            </form>
            <form target="_blank" v-else action='https://merchant.intellectmoney.ru/ru/' enctype="application/x-www-form-urlencoded">
                    <input id='eshopId' type='hidden' :value='465750' name='eshopId'/>
                    <input id='orderId' type='hidden' :value='$store.getters.getUserID' name='orderId'/>
                    <input id='serviceName' type='hidden' :value='"Оплата тарифа" + $store.getters.getPayRate.name' name='serviceName'/>
                    <input id='recipientAmount' type='hidden' :value='$store.getters.getPayRate.price.en' name='recipientAmount'/>
                    <input type='hidden' value='USD' name='recipientCurrency'/>
                    <input id='recurringType' type='hidden' value='Activate' name='recurringType'/>
                    <input type=submit :value="$t('PaymentButton')"  class="btn btn--gradient" /><br/>
            </form>
            </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import MD5 from 'crypto-js/md5';
export default {

    methods: {
        setPay() {
            if(this.locale === 'ru') {
                window.open(`https://merchant.intellectmoney.ru/ru/?eshopId=465750&orderId=${this.$store.getters.getUserID}:${this.$store.getters.getPayRate.name}&recipientAmount=${this.$store.getters.getPayRate.price.ru}&recipientCurrency=RUB`, '_blank')
            } else {
                window.open(`https://merchant.intellectmoney.ru/ru/?eshopId=465750&orderId=${this.$store.getters.getUserID}:${this.$store.getters.getPayRate.name}&recipientAmount=${this.$store.getters.getPayRate.price.ru}&recipientCurrency=RUB`, '_blank')
            }
        }
    },
    computed: {
        hash() {
            let hash = '465750' +"::" + this.$store.getters.getUserID + "::" + "Оплата тарифа" + this.$store.getters.getPayRate.name + "::"+ this.$store.getters.getPayRate.price.ru + "::"+ 'RUB' + "::"+ 'Activate' + "::" + 'Df9k2l6V4s';
            return MD5(hash)
        }
    },
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
    .payment {
        background: var(--bg-color);
        border-radius: 24px;
        display: flex;
        justify-content: center;
        padding: 14px 0;
        margin-top: 16px;
        margin-bottom: 24px;

        &__info {
            display: flex;
            align-items: center;
            img {
                width: 65px;
                height: 65px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 24px;
            }
        }

        &__content {
            text-align: center;
        }

        &__title {
            @include h1;
        }

        &__label {
            @include footnote;
        }

    }

    input[name='email'] {
        @include input;
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;
        &.error {
            border-color: #E73333
        }
    }
</style>