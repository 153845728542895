<template lang="">
  <div class="modal">
    <div class="modal__backdrop" @click="modalClose">
    </div>
    <div class="modal__window">
      <div class="modal__header">
        <button class="modal__close" @click="modalClose">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8535 19.1465L12.7069 12L19.8535 4.85343C20.0406 4.65971 20.0406 4.35252 19.8535 4.15873C19.6616 3.96012 19.3451 3.95463 19.1465 4.14646L11.9999 11.293L4.85345 4.14652C4.65973 3.95939 4.35254 3.95939 4.15875 4.14652C3.96014 4.33836 3.95465 4.65488 4.14648 4.85349L11.293 12L4.14648 19.1465C4.05273 19.2402 4.00006 19.3673 4.00006 19.4999C4 19.776 4.22388 19.9999 4.5 20C4.63263 20.0001 4.75983 19.9474 4.85345 19.8534L11.9999 12.7069L19.1465 19.8535C19.2401 19.9474 19.3674 20.0002 19.5 20C19.6325 19.9999 19.7596 19.9473 19.8533 19.8536C20.0486 19.6584 20.0487 19.3418 19.8535 19.1465Z"
              fill="#F9F9F9"
            />
          </svg>
        </button>
        <h3 class="modal__title">{{ title }}</h3>
        <button
          class="modal__info"
          :class="{
            'modal__info--hidden': hasInfo === false,
          }"
          @click="showInfo = true"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5955 8.10529C11.2598 7.7594 9.89667 8.56177 9.55078 9.89746C9.48175 10.165 9.64264 10.4378 9.91016 10.5068C10.1777 10.5759 10.4505 10.415 10.5195 10.1475C10.6462 9.65424 11.0146 9.25903 11.4977 9.09796C12.2835 8.836 13.1328 9.26068 13.3947 10.0464C13.6567 10.8322 13.2321 11.6815 12.4463 11.9434C11.8427 12.1677 11.4483 12.7508 11.4648 13.3945V14.023C11.465 14.299 11.6888 14.5226 11.9648 14.5225C12.2408 14.5223 12.465 14.2985 12.4648 14.0225V13.3945C12.4513 13.1797 12.5723 12.9789 12.7686 12.8906C13.5674 12.6208 14.1763 11.9662 14.3877 11.15C14.7336 9.81433 13.9312 8.45117 12.5955 8.10529ZM11.9647 15.3974C11.6194 15.3974 11.3397 15.6772 11.3397 16.0224C11.3397 16.3676 11.6194 16.6474 11.9647 16.6474C12.3098 16.6474 12.5897 16.3676 12.5897 16.0224C12.5897 15.6772 12.3098 15.3974 11.9647 15.3974ZM12 2C6.47717 2 2 6.47717 2 12C2 17.5228 6.47717 22 12 22C17.5201 21.9935 21.9935 17.5201 22 12C22 6.47717 17.5228 2 12 2ZM12 21C7.02942 21 3 16.9706 3 12C3 7.02942 7.02942 3 12 3C16.9683 3.00543 20.9946 7.03168 21 12C21 16.9706 16.9706 21 12 21Z"
              fill="#F9F9F9"
            />
          </svg>
        </button>
      </div>
      <div class="modal__content">
        <slot v-if="showInfo === false"></slot>
        <div v-else class="modal__info-content">
          <p>{{$t('PersonInfo')}}</p>
          <button class="btn btn--second" @click="showInfo = false">{{$t('icopy')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
export default {
  data() {
    return {
      showInfo: false,
    }
  },
  props: {
    hasInfo: Boolean,
    title: String,
  },
  methods: {
    modalClose() {
      this.$store.commit('closeModal')
    },
  },
  setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
};
</script>
<style scoped lang="scss">
.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &__backdrop {
      background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  }
  &__window {
    background: var(--bg-color);
    padding: 16px;
    border-radius: 24px;
    width: 100%;
    margin: 0 22px;
    z-index: 2;
  }
  &__title {
    @include h2;
    margin: 0px 6px;
    text-align: center;
  }
  &__header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  &__close {
    background: none;
    outline: none;
    border:none;
    display: flex;
    align-items: center;
  }
  &__info {
    background: none;
    display: flex;
    align-items: center;
    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
    &-content p{
      @include text;
      color:#A1A1A1;
      text-align: center;
      margin-bottom: 50px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
}
</style>
