const tg = window.Telegram.WebApp

export function useTelegram() {
    if(tg.initDataUnsafe.user) {
        return {
            tg,
            userId: tg.initDataUnsafe.user.id,
            userName: tg.initDataUnsafe.user.first_name,
            
        }
    }
    return {
        tg,
        userId: '',
        userName: '',
    }
}