<template lang="">
    <setting-header :title="$t('Privacy')"></setting-header>
   <div class="settings__text" v-if="locale === 'ru'">
       <p>Eva, глубоко ценит вашу конфиденциальность и придает ей высочайший приоритет. Этот текст поясняет, как мы собираем, используем и защищаем вашу личную информацию. Пожалуйста, ознакомьтесь с ним внимательно, чтобы полностью понять, какие данные мы получаем и как мы обеспечиваем их безопасность.
           <ol>
               <li>Собираемая информация: Мы собираем только ту информацию, которая необходима для предоставления наших услуг и улучшения пользовательского опыта. Это может включать ваше имя, адрес электронной почты, контактные данные и другую информацию, которую вы предоставляете добровольно при регистрации на нашем сайте или использовании наших сервисов.</li>
               <li>Использование информации: Мы используем вашу личную информацию исключительно для целей, связанных с предоставлением наших услуг. Мы можем использовать эту информацию для обработки заказов, обслуживания вашей учетной записи, обратной связи, а также для улучшения и персонализации наших услуг и сайта.</li>
               <li>Передача данных третьим лицам: Мы не продаем, не обмениваем и не передаем вашу личную информацию третьим сторонам без вашего явного согласия. Однако мы можем делиться информацией с надежными партнерами и поставщиками, которые помогают нам предоставлять наши услуги и поддерживать наш сайт.</li>
               <li>Защита данных: Мы предпринимаем все необходимые меры для защиты вашей личной информации от несанкционированного доступа, использования или разглашения. Мы применяем современные технические и организационные меры безопасности, чтобы гарантировать надежную защиту ваших данных.</li>
               <li>Срок хранения данных: Мы храним вашу личную информацию только столько, сколько это необходимо для достижения целей, для которых она была собрана, или в соответствии с применимыми законами.</li>
           </ol>
</p>
   </div>
   <div class="settings__text" v-else>
<p>Eva, deeply values your privacy and gives it the highest priority. This text explains how we collect, use and protect your personal information. Please read it carefully to fully understand what data we receive and how we ensure their security.
<ol>
<li>Information Collected: We collect only the information that is necessary to provide our services and improve the user experience. This may include your name, email address, contact details and other information that you voluntarily provide when registering on our website or using our services.</li>
<li>Use of Information: We use your personal information solely for purposes related to the provision of our services. We may use this information to process orders, maintain your account, provide feedback, as well as to improve and personalize our services and the site.</li>
<li>Transfer of data to third parties: We do not sell, exchange or transfer your personal information to third parties without your explicit consent. However, we may share information with reliable partners and suppliers who help us provide our services and maintain our website.</li>
<li>Data Protection: We take all necessary measures to protect your personal information from unauthorized access, use or disclosure. We apply modern technical and organizational security measures to ensure reliable protection of your data.</li>
<li>Data Retention Period: We store your personal information only for as long as it is necessary to achieve the purposes for which it was collected, or in accordance with applicable laws.</li>
</ol>
</p>
</div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingHeader from '@/components/UI/settingHeader.vue';
export default {
   components: {settingHeader},
   setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
   .settings__text {
       margin-top: 28px;
       font-weight: 400;
       line-height: 24px;
       ol {
           padding-left: 16px;
       }
   }
</style>