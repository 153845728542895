<template lang="">
    <div class="setting">
        <setting-header :title="$t('settingRef')"></setting-header>
        <reportSettings></reportSettings>
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingHeader from '@/components/UI/settingHeader.vue';
import reportSettings from '@/components/Settings/reportSettings.vue';
export default {
    components: {settingHeader, reportSettings},
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style lang="">
    
</style>