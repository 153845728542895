<template lang="">
    <setting-header :title="$t('versionDiff')"></setting-header>
    <div class="settings__list">
        <settings-checkbox  v-for="version in versions" :key="version.name" :item="version.name" :banned="version.ban_for_start" :currentItem="this.$store.state.userData.settings.DiffVersion" :title = "version.name"  @change="changeVersion(version)"></settings-checkbox>
    </div>
    <div>
        
    </div>
</template>
<script>
import { useI18n } from 'vue-i18n'
import settingHeader from '@/components/UI/settingHeader.vue';
import settingsCheckbox from '@/components/UI/settingsCheckbox.vue';
export default {
    components: {settingHeader, settingsCheckbox},
    computed: {
        versions() {
            return this.$store.state.AppSettings.DiffVersions
        },
    },
    methods: {
        isActive(version) {
           return this.$store.state.userData.settings.DiffVersion === version ? true : false
        },
        changeVersion(version) {
            this.$store.commit('setVersionDiff', version);
        }
    },
    setup() {
    const { t, locale } = useI18n({useScope:'global'})
    return {t, locale}
  }
}
</script>
<style scoped lang="scss">
     .settings__list {
        background: var(--bg-color);
        border-radius: 12px;
        margin-top: 20px;
        a {
        display: block;
        text-decoration: none;
        border-bottom: 0.5px solid rgba(161, 161, 161, 0.60);
        &:last-of-type {
        border-bottom: 0;
        }
        }
    }
    .setting__item-icon {
        margin-right: 8px;
    }
</style>